import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import Section from '../partials/Section';
import Recommendation from './Recommendation';
import { Icon } from '@a_team/ui-components';
import {
  useMutationChangeUserReviewVisibility,
  useQueryUserReviews,
} from '@src/rq/userReview';
import Profile from '@src/stores/Profile/Profile';
import AuthStore from '@src/stores/Auth';
import { ProfileViewMode } from '@src/stores/Profile/models';

interface RecommendationsProps {
  profile: Profile;
  auth: AuthStore;
}

const useStyles = createUseStyles({
  section: {
    marginTop: 70,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    position: 'relative',
  },
  seeAllButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#7000E3',
    textAlign: 'left',
    cursor: 'pointer',
    fontSize: '14px',
    marginTop: '10px',
    opacity: 1,
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 8,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  seeLessButton: {
    textAlign: 'left',
    backgroundColor: 'transparent',
    border: 'none',
    color: '#7000E3',
    cursor: 'pointer',
    fontSize: '14px',
    marginTop: '10px',
    opacity: 1,
    transition: 'opacity 0.3s ease-in-out',
    display: 'flex',
    gap: 8,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  lastRecommendation: {
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: '200px',
      background:
        'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
      pointerEvents: 'none',
    },
  },
  seeLessButtonHidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
});

const NUMBER_OF_RECOMMENDATIONS_TO_SHOW_INITIALLY = 2;

const Recommendations = ({ profile, auth }: RecommendationsProps) => {
  const styles = useStyles();
  const [showAll, setShowAll] = useState(false);

  const isEditMode = profile.mode === ProfileViewMode.Edit;

  const { data } = useQueryUserReviews({
    uid: profile.data.uid,
    includeAllReviews: isEditMode,
  });

  const { mutateAsync } = useMutationChangeUserReviewVisibility({
    uid: profile.data.uid,
    includeAllReviews: isEditMode,
  });

  if (!data || data?.length === 0) {
    return null;
  }

  const isAuthUser = auth.uid === profile.data.uid;
  const allRecommendations = data;

  const displayedRecommendations = showAll
    ? allRecommendations
    : allRecommendations.slice(0, NUMBER_OF_RECOMMENDATIONS_TO_SHOW_INITIALLY);

  const handleSeeAllClick = () => {
    setShowAll(true);
  };

  const handleSeeLessClick = () => {
    setShowAll(false);
  };

  const handleVisibilityChange = async (reviewId: string, visible: boolean) => {
    try {
      await mutateAsync({ reviewId, visible });
    } catch (error) {
      console.error('Error changing review visibility:', error);
    }
  };

  return (
    <Section
      className={styles.section}
      title="Recommendations"
      type="large"
      description={
        isAuthUser &&
        'Reviews help you stand out on proposals as a builder who has previously excelled on A.Team.'
      }
      dataTestingId={'profile-about-you-text'}
    >
      <div className={styles.container}>
        {displayedRecommendations.map((recommendation, index) => (
          <div
            key={index}
            className={
              index === displayedRecommendations.length - 1 &&
              !showAll &&
              allRecommendations.length >
                NUMBER_OF_RECOMMENDATIONS_TO_SHOW_INITIALLY
                ? styles.lastRecommendation
                : ''
            }
          >
            <Recommendation
              {...recommendation}
              isEditMode={isEditMode}
              onVisibilityChange={(visible) =>
                handleVisibilityChange(recommendation.id, visible)
              }
            />
          </div>
        ))}
        {allRecommendations.length > 4 && (
          <>
            {!showAll && (
              <button
                className={styles.seeAllButton}
                onClick={handleSeeAllClick}
              >
                See all {allRecommendations.length} reviews{' '}
                <Icon name="arrowDown" size={'sm'} color="Hannibal@600" />
              </button>
            )}
            {showAll && (
              <button
                className={`${styles.seeLessButton} ${
                  !showAll ? styles.seeLessButtonHidden : ''
                }`}
                onClick={handleSeeLessClick}
              >
                See Less{' '}
                <Icon name="arrowUp" size={'sm'} color="Hannibal@600" />
              </button>
            )}
          </>
        )}
      </div>
    </Section>
  );
};

export default Recommendations;
