import React, { ReactElement, ReactNode, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { format } from 'date-fns';
import { find } from 'lodash';
import { Colors, Icon, IconType, formatLocation } from '@ateams/components';
import { DateISOString } from '@a_team/models/dist/misc';
import { AdminNotesScore } from '@a_team/models/dist/AdminNotesObject';
import { AvailableType } from '@a_team/models/dist/AvailabilityObject';
import {
  UserTalentSkillAssignmentData,
  UserTalentSkillAssignment,
} from '@a_team/models/dist/TalentCategories';
import { SkillTargeterUserObject } from '@a_team/models/dist/TeamGraphObject';
import { RoleFilter } from '@src/components/TeamGraphBuilderQuery';
import {
  AccentScoreOptions,
  EnglishLevelOptions,
  ExpertiseScoreOptions,
  InteractionExperienceOptions,
} from '@src/components/ScoreInput/Scores';
import { Card } from '@ateams/components';
import UserAvatar from '@src/components/UserAvatar';
import MatchContainer from './MatchContainer';
import UserBadges from '@src/components/UserBadges';
import { MatchesDetailsModal } from '@src/views/SkillTargeter/TargeterUserCardView/MatchesDetailsModal';

interface Props {
  user: SkillTargeterUserObject;
  selectBuilder: () => void;
  filter?: RoleFilter;
  selected?: boolean;
  duplicated?: boolean;
  onClick?: () => void;
  showDetails?: boolean;
  hourlyRateComponent?: ReactNode;
  blurbComponent?: ReactElement;
  skillsComponent?: ReactElement;
}

const useStyles = createUseStyles({
  listItem: {
    listStyle: 'none',
    margin: '0 0 1.5em 0',
    cursor: 'pointer',
    maxWidth: 650,
  },
  card: (props: Props) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    boxShadow: `5px 5px 5px ${Colors.regularLight}`,
    overflow: 'hidden',
    background: props.duplicated
      ? Colors.dangerLight
      : props.selected
      ? Colors.primaryVeryLight
      : Colors.backgroundWhite,
    border: props.duplicated
      ? `1px solid ${Colors.danger}`
      : props.selected
      ? `1px solid ${Colors.primaryLight}`
      : `1px solid transparent`,
  }),
  userTitleContainer: {
    display: 'flex',
    padding: '1em',
    justifyContent: 'space-between',
  },
  userTitles: {
    paddingLeft: '1em',
    alignSelf: 'flex-end',
  },
  userInfoContainer: {
    display: 'flex',
    padding: '1em',
    paddingLeft: '2em',
    marginLeft: 46,
    justifyContent: 'space-between',
  },
  userInfoColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  userDetail: {
    fontSize: 14,
    minHeight: 24,
  },
  viewDetails: {
    alignSelf: 'flex-end',
    '&:hover': {
      color: Colors.primary,
      cursor: 'pointer',
    },
  },
  starIconContainer: {
    position: 'relative',
    '& > i': {
      position: 'absolute',
      top: 0,
      right: 0,
    },
  },
});

const formatMatches = (
  matches: SkillTargeterUserObject['matchesOn'],
): string => {
  if (!matches) return '';

  const matchList: string[] = [];
  (matches.aboutMe || matches?.textAboutMe) && matchList.push('About me');
  (matches.adminNotes || matches?.textAdminNotes) &&
    matchList.push('Admin notes');
  (matches.experiences ||
    matches?.textJobExperiencesJobRole ||
    matches?.textJobExperiencesName ||
    matches?.textProjectExperiencesJobRole ||
    matches?.textProjectExperiencesTitle ||
    matches?.textProjectExperiencesDescription) &&
    matchList.push('Experiences');
  (matches.missionApplications || matches?.textMissionApplicationPitches) &&
    matchList.push('Applications');
  (matches.email || matches?.textEmail || matches?.textDisplayEmail) &&
    matchList.push('Email');
  (matches.blurbs || matches?.textBlurbs) && matchList.push('Proposal Blurbs');
  (matches?.textVettingDetailsSummary ||
    matches?.textVettingDetailsZeroToOne ||
    matches?.textVettingDetailsPeopleManaged) &&
    matchList.push('Vetting Feedback');

  return matchList.join(' | ');
};

const formatAvailability = (
  availability: SkillTargeterUserObject['availability'],
): string => {
  switch (availability?.type) {
    case AvailableType.NotAvailable:
      return `Not Available`;
    case AvailableType.Now:
      return `Available ${availability.weeklyHoursAvailable} hours weekly`;
    case AvailableType.FutureDate:
      // eslint-disable-next-line no-case-declarations
      const availabilityDate = new Date(
        availability.availableFrom as DateISOString,
      );
      return availabilityDate.getTime() < Date.now()
        ? `Available ${availability.weeklyHoursAvailable} hours weekly`
        : `Available ${format(availabilityDate, 'MMM d')}, for ${
            availability.weeklyHoursAvailable
          } hours weekly`;
    default:
      return `Unknown Availability`;
  }
};

const hasScore = (score?: AdminNotesScore): boolean => Number.isInteger(score);

const findMatchingSkills = (
  skillQuery: string[],
  skills: UserTalentSkillAssignment[],
): UserTalentSkillAssignment[] => {
  return skillQuery
    .map((skill: string) => skill.split('-')[0])
    .map((skill: string) =>
      find(skills, ({ talentSkillId }) => talentSkillId === skill),
    )
    .filter(
      (
        skill: UserTalentSkillAssignment | undefined,
      ): skill is UserTalentSkillAssignment => !!skill,
    );
};

const getSkills = (
  filter?: RoleFilter,
  skills?: UserTalentSkillAssignmentData,
): {
  requiredSkills: UserTalentSkillAssignment[];
  preferredSkills: UserTalentSkillAssignment[];
} => {
  if (!filter) return { requiredSkills: [], preferredSkills: [] };

  const allSkills: UserTalentSkillAssignment[] = [
    ...(skills?.mainTalentSkills ?? []),
    ...(skills?.additionalTalentSkills ?? []),
  ];

  return {
    requiredSkills: findMatchingSkills(filter.requiredSkills ?? [], allSkills),
    preferredSkills: findMatchingSkills(
      filter.preferredSkills ?? [],
      allSkills,
    ),
  };
};

const TargeterUserCard = (props: Props): ReactElement => {
  const {
    user,
    filter,
    selected,
    selectBuilder,
    hourlyRateComponent,
    blurbComponent,
    skillsComponent,
    showDetails = true,
  } = props;
  const styles = useStyles(props);
  const [matchesDetailsModalOpen, setMatchesDetailsModalOpen] = useState(false);

  const matchesOn: string = formatMatches(user.matchesOn);
  const availability: string = formatAvailability(user.availability);
  const location = user.location ? formatLocation(user.location) : null;
  const defaultHourlyRateRange =
    !user.rateRange || (!user.rateRange.min && user.rateRange.max === 1000);
  const userMaxRate =
    !user?.rateRange?.max && user?.rateRange?.max !== 0
      ? 'N/A'
      : `$${user?.rateRange?.max}`;
  const userMinRate =
    !user?.rateRange?.min && user?.rateRange?.min !== 0
      ? 'N/A'
      : `$${user?.rateRange?.min}`;

  const role =
    user.talentProfile?.talentSpecializations?.mainTalentSpecialization?.name ??
    user.roleCategory?.title;

  const skills = getSkills(filter, user.talentProfile?.talentSkills);

  const expertiseScore = hasScore(user.expertiseScore)
    ? ExpertiseScoreOptions.find(({ value }) => value === user.expertiseScore)
        ?.label
    : undefined;

  const interactionExperienceScore = hasScore(user.interactionExperienceScore)
    ? InteractionExperienceOptions.find(
        ({ value }) => value === user.interactionExperienceScore,
      )?.label
    : undefined;

  const englishScore = hasScore(user.englishScore)
    ? EnglishLevelOptions.find(({ value }) => value === user.englishScore)
        ?.label
    : undefined;

  const accentScore = hasScore(user.accentScore)
    ? AccentScoreOptions.find(({ value }) => value === user.accentScore)?.label
    : undefined;

  return (
    <li className={styles.listItem}>
      <Card className={styles.card} onClick={props.onClick}>
        <div className={styles.userTitleContainer}>
          <div style={{ display: 'flex' }}>
            <a
              data-testing-id={'targeter-result-card-user-link'}
              href={`/${user.username}`}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
            >
              <UserAvatar
                src={user.profilePictureURL}
                badges={user.badges}
                size={46}
              />
            </a>
            <div className={styles.userTitles}>
              <div style={{ display: 'flex', gap: 8 }}>
                <div style={{ fontWeight: 'bold' }}>{user.fullName}</div>
                <UserBadges badges={user.badges} />
              </div>
              <div className={styles.userDetail}>{role}</div>
              {user.linkedIn && (
                <a
                  href={user.linkedIn.profileUrl}
                  target="_blank"
                  rel="noreferrer"
                  onClick={(e) => e.stopPropagation()}
                >
                  <Icon
                    type={IconType.LinkedIn}
                    style={{ cursor: 'pointer' }}
                  />
                </a>
              )}
            </div>
          </div>
          <div className={styles.starIconContainer}>
            <Icon
              style={{ visibility: selected ? 'visible' : 'hidden' }}
              size="medium"
              type={IconType.Star}
              onClick={(e) => {
                e?.stopPropagation();
                selectBuilder();
              }}
            />
            <Icon
              style={{ visibility: selected ? 'hidden' : 'visible' }}
              size="medium"
              type={IconType.EmptyStar}
              onClick={(e) => {
                e?.stopPropagation();
                selectBuilder();
              }}
            />
          </div>
        </div>
        <div className={styles.userInfoContainer}>
          <div className={styles.userInfoColumn} style={{ minWidth: '50%' }}>
            <div className={styles.userDetail}>{availability}</div>
            <div className={styles.userDetail}>{user.email}</div>
            {location && <div className={styles.userDetail}>{location}</div>}
            {!defaultHourlyRateRange &&
              (userMinRate || userMaxRate) &&
              !(blurbComponent || skillsComponent) &&
              (hourlyRateComponent || (
                <div className={styles.userDetail}>
                  {userMinRate === userMaxRate
                    ? `Hourly Rate: ${userMinRate}`
                    : `Hourly Rate: ${userMinRate} - ${userMaxRate}`}
                </div>
              ))}
          </div>
          <div className={styles.userInfoColumn} style={{ flexGrow: 1 }}>
            {Number.isInteger(expertiseScore) && (
              <div className={styles.userDetail}>
                {`Expertise: ${expertiseScore}`}
              </div>
            )}
            {Number.isInteger(interactionExperienceScore) && (
              <div className={styles.userDetail}>
                {`Interaction XP: ${interactionExperienceScore}`}
              </div>
            )}
            {Number.isInteger(englishScore) && (
              <div className={styles.userDetail}>
                {`English: ${englishScore}`}
              </div>
            )}
            {Number.isInteger(accentScore) && (
              <div className={styles.userDetail}>
                {`Accent: ${accentScore}`}
              </div>
            )}
          </div>
        </div>

        {(hourlyRateComponent || blurbComponent || skillsComponent) && (
          <div
            style={{
              margin: 15,
              background: '#fff',
              border: '1px solid #FFCBAE',
              padding: 16,
              borderRadius: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 24,
            }}
          >
            {hourlyRateComponent && hourlyRateComponent}
            {blurbComponent && blurbComponent}
            {skillsComponent && skillsComponent}
          </div>
        )}
        {showDetails && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: Colors.backgroundMedium,
              padding: '0.5em 1em',
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setMatchesDetailsModalOpen(true);
            }}
          >
            <MatchContainer
              matchesOn={matchesOn}
              requiredSkills={skills.requiredSkills}
              preferredSkills={skills.preferredSkills}
            />
            <div className={styles.viewDetails}>View details</div>
          </div>
        )}
      </Card>
      {matchesDetailsModalOpen && (
        <MatchesDetailsModal
          matchesOn={matchesOn}
          user={user}
          filter={filter}
          open={matchesDetailsModalOpen}
          onClose={() => setMatchesDetailsModalOpen(false)}
        />
      )}
    </li>
  );
};

export default TargeterUserCard;
