import React, { CSSProperties, ReactElement, SyntheticEvent } from 'react';
import cx from 'classnames';
import { createUseStyles } from 'react-jss';

// all icons
import website from '../assets/icons/website.svg';
import dribbble from '../assets/icons/dribbble.svg';
import behance from '../assets/icons/behance.svg';
import linkedin from '../assets/icons/linkedin.svg';
import github from '../assets/icons/github.svg';
import back from '../assets/icons/back.svg';
import share from '../assets/icons/share.svg';
import close from '../assets/icons/close.svg';
import closeLight from '../assets/icons/closeLight.svg';
import closeRed from '../assets/icons/closeRed.svg';
import person from '../assets/icons/person.svg';
import teams from '../assets/icons/teams.svg';
import roles from '../assets/icons/roles.svg';
import openRoles from '../assets/icons/openRoles.svg';
import articles from '../assets/icons/articles.svg';
import next from '../assets/icons/next.svg';
import arrowDown from '../assets/icons/arrowDown.svg';
import arrowDownSecondary from '../assets/icons/arrowDownSecondary.svg';
import arrowDownBlack from '../assets/icons/arrowDownBlack.svg';
import arrowUp from '../assets/icons/arrowUp.svg';
import shareGrey from '../assets/icons/projectShare.svg';
import editGray from '../assets/icons/grayEdit.svg';
import leave from '../assets/icons/leave.svg';
import trashRed from '../assets/icons/trashRed.svg';
import search from '../assets/icons/search.svg';
import searchGrey from '../assets/icons/searchGrey.svg';
import heart from '../assets/icons/heart.svg';
import inbox from '../assets/icons/inbox.svg';
import grid from '../assets/icons/grid.svg';
import caretDown from '../assets/icons/caretDown.svg';
import clock from '../assets/icons/clock.svg';
import calendar from '../assets/icons/calendar.svg';
import plus from '../assets/icons/plus.svg';
import plusOrange from '../assets/icons/plusOrange.svg';
import info from '../assets/icons/info.svg';
import article from '../assets/icons/article.svg';
import award from '../assets/icons/award.svg';
import tv from '../assets/icons/tv.svg';
import job from '../assets/icons/job.svg';
import education from '../assets/icons/education.svg';
import file from '../assets/icons/file.svg';
import building from '../assets/icons/building.svg';
import arrowBack from '../assets/icons/arrowBack.svg';
import downArrowGray from '../assets/icons/downArrowGray.svg';
import upload from '../assets/icons/upload.svg';
import Menu from '../assets/icons/menu.svg';
import Check from '../assets/icons/check.svg';
import CheckBlack from '../assets/icons/checkBlack.svg';
import CheckWhite from '../assets/icons/checkWhite.svg';
import Approve from '../assets/icons/approve.svg';
import ArrowRight from '../assets/icons/arrowRight.svg';
import OrangeDownload from '../assets/icons/orangeDownload.svg';
import lock from '../assets/icons/lock.svg';
import privacy from '../assets/icons/privacy.svg';
import more from '../assets/icons/more.svg';
import Money from '../assets/icons/money.svg';
import MoneyGray from '../assets/icons/moneyGray.svg';
import CreditCard from '../assets/icons/creditCard.svg';
import RecommendedBadge from '../assets/icons/recommendedBadge.svg';
import chevron from '../assets/icons/chevron.svg';
import tag from '../assets/icons/tag.svg';
import selectionTeam from '../assets/icons/selectionTeam.svg';
import pencil from '../assets/icons/pencil.svg';
import star from '../assets/icons/star.svg';
import emptyStar from '../assets/icons/star-empty.svg';
import Applicant from '../assets/icons/applicant.svg';
import TeamGraph from '../assets/icons/teamGraph.svg';
import Eye from '../assets/icons/eye.svg';
import EyeClosed from '../assets/icons/eyeClosed.svg';
import Play from '../assets/icons/play.svg';
import Pause from '../assets/icons/pause.svg';
import Users from '../assets/icons/users.svg';
import UsersGray from '../assets/icons/usersGray.svg';
import ATeamGray from '../assets/icons/aTeamGray.svg';
import undo from '../assets/icons/undo.svg';
import Points from '../assets/icons/points.svg';
import Copy from '../assets/icons/copy.svg';
import Twitter from '../assets/icons/twitter.svg';
import LinkedInDark from '../assets/icons/linkedinDark.svg';
import Facebook from '../assets/icons/facebook.svg';
import DropdownOpen from '../assets/icons/dropdownOpen.svg';
import Low from '../assets/icons/low.svg';
import World from '../assets/icons/world.svg';
import Location from '../assets/icons/location.svg';
import Missing from '../assets/icons/missing.svg';
import PurpleStar from '../assets/icons/purpleStar.svg';
import RedX from '../assets/icons/redX.svg';
import Link from '../assets/icons/link.svg';
import Chat from '../assets/icons/chat.svg';
import Connect from '../assets/icons/connect.svg';
import Edit from '../assets/icons/edit.svg';
import BlackArrowDown from '../assets/icons/blackArrowDown.svg';
import AddConnectionWhite from '../assets/icons/addConnectionWhite.svg';
import RemoveConnectionGrey from '../assets/icons/removeConnectionGrey.svg';
import CloseGrey from '../assets/icons/closeGrey.svg';
import CloseOrange from '../assets/icons/closeOrange.svg';
import Mail from '../assets/icons/mail.svg';
import SuggestedTeams from '../assets/icons/suggestedTeams.svg';
import SuggestedTeamsInactive from '../assets/icons/suggestedTeamsInactive.svg';
import WorkTogether from '../assets/icons/workTogether.svg';
import WorkConnection from '../assets/icons/workConnection.svg';
import SocialConnection from '../assets/icons/socialConnection.svg';
import CircleLoadingViolet from '../assets/icons/circleLoadingViolet.svg';
import CircleLoadingRed from '../assets/icons/circleLoadingRed.svg';
import Cash from '../assets/icons/cash.svg';
import Dot from '../assets/icons/dot.svg';
import InfoDark from '../assets/icons/infoDark.svg';
import Company from '../assets/icons/company.svg';
import PendingGray from '../assets/icons/pendingGray.svg';
import CloseGray from '../assets/icons/closeGray.svg';
import TicketGray from '../assets/icons/ticketGray.svg';
import Suitcase from '../assets/icons/suitcase.svg';
import ATeamsConnection from '../assets/icons/aTeamsConnection.svg';
import TeamCollection from '../assets/icons/teamCollection.svg';
import Banknotes from '../assets/icons/banknotes.svg';
import User from '../assets/icons/user.svg';
import PaperAndPencil from '../assets/icons/paperAndPencil.svg';
import SuitcaseGray from '../assets/icons/suitcaseGray.svg';
import PlusPurple from '../assets/icons/plusPurple.svg';
import CheckPurple from '../assets/icons/checkPurple.svg';
import HorizontalInLineDivider from '../assets/icons/horizontalInLineDivider.svg';
import PlusBlack from '../assets/icons/plusBlack.svg';
import EditPencilGray from '../assets/icons/editPencilGray.svg';
import Collaborator from '../assets/icons/collaborator.svg';
import Match from '../assets/icons/match.svg';
import Application from '../assets/icons/application.svg';
import CrystalBall from '../assets/icons/crystalBall.svg';
import StarredBuilder from '../assets/icons/starredBuilder.svg';
import StarsPurple from '../assets/icons/starsPurple.svg';
import StarsWhite from '../assets/icons/starsWhite.svg';
import RemovePurple from '../assets/icons/removePurple.svg';
import closeRedCircle from '../assets/icons/closeRedCircle.svg';
import GreyEllipse from '../assets/icons/greyEllipse.svg';
import connectedPositive from '../assets/icons/connectedPositive.svg';
import TrashBlack from '../assets/icons/trashBlack.svg';
import linkArrow from '../assets/icons/linkArrow.svg';
import google from '../assets/icons/google.svg';
import CollaboratorBlack from '../assets/icons/collaboratorBlack.svg';
import BookOpen from '../assets/icons/bookOpen.svg';
import Settings from '../assets/icons/settings.svg';
import CompanyGray from '../assets/icons/companyGray.svg';
import LeftArrowDark from '../assets/icons/leftArrowDark.svg';
import Proposal from '../assets/icons/proposal.svg';
import PurpleRibbon from '../assets/icons/purpleRibbon.svg';
import SendDark from '../assets/icons/sendDark.svg';
import SendLight from '../assets/icons/sendLight.svg';
import Targeter from '../assets/icons/targeter.svg';
import TeamUpLight from '../assets/icons/teamUpLight.svg';
import ArrowUpRight from '../assets/icons/arrowUpRight.svg';
import CrystalBallWhite from '../assets/icons/crystalBallWhite.svg';
import SearchPurple from '../assets/icons/searchPurple.svg';
import StarOrange from '../assets/icons/starOrange.svg';
import PurpleCalendar from '../assets/icons/purpleCalendar.svg';
import ErrorCalendar from '../assets/icons/errorCalendar.svg';
import DownArrowWhite from '../assets/icons/downArrowWhite.svg';
import AppleCalendar from '../assets/icons/appleCalendar.svg';
import OutlookCalendar from '../assets/icons/outlook.svg';
import LinkArrowLeft from '../assets/icons/linkArrowLeft.svg';
import Flag from '../assets/icons/flag.svg';
import Refresh from '../assets/icons/refresh.svg';
import LinkArrowLeftWhite from '../assets/icons/linkArrowLeftWhite.svg';
import BookOpenWhite from '../assets/icons/bookOpenWhite.svg';
import LinkSecondary from '../assets/icons/linkSecondary.svg';
import Download from '../assets/icons/download.svg';
import Timeshees from '../assets/icons/timesheets.svg';
import Video from '../assets/icons/video.svg';

export enum IconType {
  Website = 'Website',
  Dribbble = 'Dribbble',
  Behance = 'Behance',
  LinkedIn = 'linkedIn',
  GitHub = 'GitHub',
  Back = 'Back',
  BookOpen = 'BookOpen',
  Share = 'Share',
  Close = 'Close',
  CloseLight = 'CloseLight',
  Person = 'Person',
  Teams = 'Teams',
  Roles = 'Roles',
  OpenRoles = 'OpenRoles',
  Articles = 'Articles',
  Next = 'Next',
  ArrowDown = 'ArrowDown',
  ArrowDownSecondary = 'ArrowDownSecondary',
  ArrowDownBlack = 'ArrowDownBlack',
  ArrowUp = 'ArrowUp',
  ShareGrey = 'ShareGrey',
  EditGray = 'EditGray',
  Leave = 'Leave',
  TrashRed = 'TrashRed',
  Search = 'Search',
  SearchGrey = 'SearchGrey',
  CaretDown = 'CaretDown',
  Clock = 'Clock',
  Calendar = 'Calendar',
  Heart = 'Heart',
  Inbox = 'Inbox',
  Grid = 'Grid',
  Plus = 'Plus',
  PlusOrange = 'PlusOrange',
  Info = 'Info',
  Article = 'Article',
  Award = 'Award',
  TV = 'TV',
  Job = 'Job',
  Education = 'Education',
  File = 'File',
  Building = 'Building',
  ArrowBack = 'ArrowBack',
  DownArrowGray = 'DownArrowGray',
  Upload = 'Upload',
  Menu = 'Menu',
  Check = 'Check',
  CheckBlack = 'CheckBlack',
  CheckWhite = 'CheckWhite',
  Approve = 'Approve',
  ArrowRight = 'ArrowRight',
  OrangeDownload = 'OrangeDownload',
  Lock = 'Lock',
  Privacy = 'Privacy',
  More = 'More',
  Money = 'Money',
  MoneyGray = 'MoneyGray',
  CreditCard = 'CreditCard',
  RecommendedBadge = 'RecommendedBadge',
  Chevron = 'Chevron',
  Tag = 'Tag',
  SelectionTeam = 'SelectionTeam',
  Pencil = 'Pencil',
  Star = 'Star',
  EmptyStar = 'EmptyStar',
  Applicant = 'Applicant',
  TeamGraph = 'TeamGraph',
  Eye = 'Eye',
  EyeClosed = 'EyeClosed',
  Play = 'Play',
  Pause = 'Pause',
  Users = 'Users',
  UsersGray = 'UsersGray',
  ATeamGray = 'ATeamGray',
  Undo = 'Undo',
  Points = 'Points',
  Copy = 'Copy',
  Twitter = 'Twitter',
  LinkedInDark = 'LinkedInDark',
  Facebook = 'Facebook',
  DropdownOpen = 'DropdownOpen',
  Low = 'Low',
  World = 'World',
  Location = 'Location',
  Missing = 'Missing',
  PurpleStar = 'PurpleStar',
  RedX = 'RedX',
  Link = 'Link',
  Chat = 'Chat',
  Connect = 'Connect',
  Edit = 'Edit',
  BlackArrowDown = 'BlackArrowDown',
  AddConnectionWhite = 'AddConnectionWhite',
  RemoveConnectionGrey = 'RemoveConnectionGrey',
  CloseGrey = 'CloseGrey',
  CloseRed = 'CloseRed',
  CloseOrange = 'CloseOrange',
  CloseRedCircle = 'CloseRedCircle',
  ConnectedPositive = 'ConnectedPositive',
  Mail = 'Mail',
  SuggestedTeams = 'SuggestedTeams',
  SuggestedTeamsInactive = 'SuggestedTeamsInactive',
  WorkTogether = 'WorkTogether',
  WorkConnection = 'WorkConnection',
  SocialConnection = 'SocialConnection',
  CircleLoadingViolet = 'CircleLoadingViolet',
  CircleLoadingRed = 'CircleLoadingRed',
  Cash = 'Cash',
  Dot = 'Dot',
  InfoDark = 'InfoDark',
  Company = 'Company',
  PendingGray = 'PendingGray',
  CloseGray = 'CloseGray',
  TicketGray = 'TicketGray',
  Suitcase = 'Suitcase',
  ATeamsConnection = 'ATeamsConnection',
  TeamCollection = 'TeamCollection',
  Banknotes = 'Banknotes',
  User = 'User',
  PaperAndPencil = 'PaperAndPencil',
  SuitcaseGray = 'SuitcaseGray',
  PlusPurple = 'PlusPurple',
  CheckPurple = 'CheckPurple',
  HorizontalInLineDivider = 'HorizontalInLineDivider',
  PlusBlack = 'PlusBlack',
  EditPencilGray = 'EditPencilGray',
  Collaborator = 'Collaborator',
  Match = 'Match',
  Application = 'Application',
  CrystalBall = 'CrystalBall',
  StarredBuilder = 'StarredBuilder',
  StarsPurple = 'StarsPurple',
  StarsWhite = 'StarsWhite',
  RemovePurple = 'RemovePurple',
  GreyEllipse = 'GreyEllipse',
  TrashBlack = 'TrashBlack',
  LinkArrow = 'LinkArrow',
  Google = 'Google',
  CollaboratorBlack = 'CollaboratorBlack',
  Settings = 'Settings',
  CompanyGray = 'CompanyGray',
  LeftArrowDark = 'LeftArrowDark',
  Proposal = 'Proposal',
  PurpleRibbon = 'PurpleRibbon',
  SendDark = 'SendDark',
  SendLight = 'SendLight',
  Targeter = 'Targeter',
  TeamUpLight = 'TeamUpLight',
  ArrowUpRight = 'ArrowUpRight',
  CrystalBallWhite = 'CrystalBallWhite',
  SearchPurple = 'SearchPurple',
  StarOrange = 'StarOrange',
  PurpleCalendar = 'PurpleCalendar',
  ErrorCalendar = 'ErrorCalendar',
  DownArrowWhite = 'DownArrowWhite',
  AppleCalendar = 'AppleCalendar',
  OutlookCalendar = 'OutlookCalendar',
  LinkArrowLeft = 'LinkArrowLeft',
  Flag = 'Flag',
  Refresh = 'Refresh',
  LinkArrowLeftWhite = 'LinkArrowLeftWhite',
  BookOpenWhite = 'BookOpenWhite',
  LinkSecondary = 'LinkSecondary',
  Download = 'Download',
  Timesheets = 'Timesheets',
  Video = 'Video',
}

export const SizeMap = {
  xxsmall: '0.6em',
  xsmall: '0.8em',
  exact: '1em',
  smaller: '1.2em',
  small: '1.5em',
  medium: '1.8em',
  large: '2.5em',
  xlarge: '4em',
};

export interface IconProps {
  type: IconType;
  size?: keyof typeof SizeMap;
  className?: string;
  style?: CSSProperties;
  muted?: boolean;
  invert?: boolean;
  flip?: 'horizontal' | 'vertical' | 'both';
  rotating?: boolean;
  title?: string | boolean;
  onClick?(e?: SyntheticEvent): void;
  clickable?: boolean;
  testingId?: string;
}

const Icons = {
  [IconType.Website]: website,
  [IconType.Dribbble]: dribbble,
  [IconType.Behance]: behance,
  [IconType.LinkedIn]: linkedin,
  [IconType.GitHub]: github,
  [IconType.Back]: back,
  [IconType.BookOpen]: BookOpen,
  [IconType.Share]: share,
  [IconType.Close]: close,
  [IconType.CloseLight]: closeLight,
  [IconType.CloseRed]: closeRed,
  [IconType.CloseOrange]: CloseOrange,
  [IconType.CloseRedCircle]: closeRedCircle,
  [IconType.ConnectedPositive]: connectedPositive,
  [IconType.Person]: person,
  [IconType.Teams]: teams,
  [IconType.Roles]: roles,
  [IconType.OpenRoles]: openRoles,
  [IconType.Articles]: articles,
  [IconType.Next]: next,
  [IconType.ArrowDown]: arrowDown,
  [IconType.ArrowDownSecondary]: arrowDownSecondary,
  [IconType.ArrowDownBlack]: arrowDownBlack,
  [IconType.ArrowUp]: arrowUp,
  [IconType.ShareGrey]: shareGrey,
  [IconType.EditGray]: editGray,
  [IconType.Leave]: leave,
  [IconType.TrashRed]: trashRed,
  [IconType.Search]: search,
  [IconType.SearchGrey]: searchGrey,
  [IconType.CaretDown]: caretDown,
  [IconType.Clock]: clock,
  [IconType.Calendar]: calendar,
  [IconType.Heart]: heart,
  [IconType.Inbox]: inbox,
  [IconType.Grid]: grid,
  [IconType.Plus]: plus,
  [IconType.PlusOrange]: plusOrange,
  [IconType.Info]: info,
  [IconType.Article]: article,
  [IconType.Award]: award,
  [IconType.TV]: tv,
  [IconType.Job]: job,
  [IconType.Education]: education,
  [IconType.File]: file,
  [IconType.Building]: building,
  [IconType.ArrowBack]: arrowBack,
  [IconType.DownArrowGray]: downArrowGray,
  [IconType.Upload]: upload,
  [IconType.Menu]: Menu,
  [IconType.Check]: Check,
  [IconType.CheckBlack]: CheckBlack,
  [IconType.CheckWhite]: CheckWhite,
  [IconType.Approve]: Approve,
  [IconType.ArrowRight]: ArrowRight,
  [IconType.OrangeDownload]: OrangeDownload,
  [IconType.Lock]: lock,
  [IconType.Privacy]: privacy,
  [IconType.More]: more,
  [IconType.Money]: Money,
  [IconType.MoneyGray]: MoneyGray,
  [IconType.CreditCard]: CreditCard,
  [IconType.RecommendedBadge]: RecommendedBadge,
  [IconType.Chevron]: chevron,
  [IconType.Tag]: tag,
  [IconType.SelectionTeam]: selectionTeam,
  [IconType.Pencil]: pencil,
  [IconType.Star]: star,
  [IconType.EmptyStar]: emptyStar,
  [IconType.Applicant]: Applicant,
  [IconType.TeamGraph]: TeamGraph,
  [IconType.Eye]: Eye,
  [IconType.EyeClosed]: EyeClosed,
  [IconType.Play]: Play,
  [IconType.Pause]: Pause,
  [IconType.Users]: Users,
  [IconType.UsersGray]: UsersGray,
  [IconType.ATeamGray]: ATeamGray,
  [IconType.Undo]: undo,
  [IconType.Points]: Points,
  [IconType.Copy]: Copy,
  [IconType.Twitter]: Twitter,
  [IconType.LinkedInDark]: LinkedInDark,
  [IconType.Facebook]: Facebook,
  [IconType.DropdownOpen]: DropdownOpen,
  [IconType.Low]: Low,
  [IconType.World]: World,
  [IconType.Location]: Location,
  [IconType.Missing]: Missing,
  [IconType.PurpleStar]: PurpleStar,
  [IconType.RedX]: RedX,
  [IconType.Link]: Link,
  [IconType.Chat]: Chat,
  [IconType.Connect]: Connect,
  [IconType.Edit]: Edit,
  [IconType.BlackArrowDown]: BlackArrowDown,
  [IconType.AddConnectionWhite]: AddConnectionWhite,
  [IconType.RemoveConnectionGrey]: RemoveConnectionGrey,
  [IconType.CloseGrey]: CloseGrey,
  [IconType.Mail]: Mail,
  [IconType.SuggestedTeams]: SuggestedTeams,
  [IconType.SuggestedTeamsInactive]: SuggestedTeamsInactive,
  [IconType.WorkTogether]: WorkTogether,
  [IconType.WorkConnection]: WorkConnection,
  [IconType.SocialConnection]: SocialConnection,
  [IconType.CircleLoadingViolet]: CircleLoadingViolet,
  [IconType.CircleLoadingRed]: CircleLoadingRed,
  [IconType.Cash]: Cash,
  [IconType.Dot]: Dot,
  [IconType.InfoDark]: InfoDark,
  [IconType.Company]: Company,
  [IconType.Suitcase]: Suitcase,
  [IconType.PendingGray]: PendingGray,
  [IconType.CloseGray]: CloseGray,
  [IconType.TicketGray]: TicketGray,
  [IconType.ATeamsConnection]: ATeamsConnection,
  [IconType.TeamCollection]: TeamCollection,
  [IconType.Banknotes]: Banknotes,
  [IconType.User]: User,
  [IconType.PaperAndPencil]: PaperAndPencil,
  [IconType.SuitcaseGray]: SuitcaseGray,
  [IconType.PlusPurple]: PlusPurple,
  [IconType.CheckPurple]: CheckPurple,
  [IconType.HorizontalInLineDivider]: HorizontalInLineDivider,
  [IconType.PlusBlack]: PlusBlack,
  [IconType.EditPencilGray]: EditPencilGray,
  [IconType.Collaborator]: Collaborator,
  [IconType.Match]: Match,
  [IconType.Application]: Application,
  [IconType.CrystalBall]: CrystalBall,
  [IconType.StarredBuilder]: StarredBuilder,
  [IconType.StarsPurple]: StarsPurple,
  [IconType.StarsWhite]: StarsWhite,
  [IconType.RemovePurple]: RemovePurple,
  [IconType.GreyEllipse]: GreyEllipse,
  [IconType.TrashBlack]: TrashBlack,
  [IconType.LinkArrow]: linkArrow,
  [IconType.Google]: google,
  [IconType.CollaboratorBlack]: CollaboratorBlack,
  [IconType.Settings]: Settings,
  [IconType.CompanyGray]: CompanyGray,
  [IconType.LeftArrowDark]: LeftArrowDark,
  [IconType.Proposal]: Proposal,
  [IconType.PurpleRibbon]: PurpleRibbon,
  [IconType.SendDark]: SendDark,
  [IconType.SendLight]: SendLight,
  [IconType.Targeter]: Targeter,
  [IconType.TeamUpLight]: TeamUpLight,
  [IconType.ArrowUpRight]: ArrowUpRight,
  [IconType.CrystalBallWhite]: CrystalBallWhite,
  [IconType.SearchPurple]: SearchPurple,
  [IconType.StarOrange]: StarOrange,
  [IconType.PurpleCalendar]: PurpleCalendar,
  [IconType.ErrorCalendar]: ErrorCalendar,
  [IconType.DownArrowWhite]: DownArrowWhite,
  [IconType.AppleCalendar]: AppleCalendar,
  [IconType.OutlookCalendar]: OutlookCalendar,
  [IconType.LinkArrowLeft]: LinkArrowLeft,
  [IconType.Flag]: Flag,
  [IconType.Refresh]: Refresh,
  [IconType.LinkArrowLeftWhite]: LinkArrowLeftWhite,
  [IconType.BookOpenWhite]: BookOpenWhite,
  [IconType.LinkSecondary]: LinkSecondary,
  [IconType.Download]: Download,
  [IconType.Timesheets]: Timeshees,
  [IconType.Video]: Video,
};

const useStyles = createUseStyles({
  root: {
    display: 'inline-block',
    verticalAlign: 'middle',
    width: '1em',
    height: '1em',
    fontSize: (props: IconProps): string | undefined =>
      SizeMap[props.size || 'small'],
    backgroundImage: (props: IconProps): string => `url(${Icons[props.type]})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    transform: (props: IconProps): string =>
      props.flip
        ? `${
            props.flip === 'horizontal' || props.flip === 'both'
              ? 'scaleX(-1)'
              : ''
          } ${
            props.flip === 'vertical' || props.flip === 'both'
              ? 'scaleY(-1)'
              : ''
          }`
        : 'none',
    filter: (props: IconProps): string =>
      `${props.muted ? 'grayscale(1) contrast(0)' : ''} ${
        props.invert ? 'invert(1) contrast(100)' : ''
      }`,
    cursor: (props: IconProps): string =>
      props.onClick || props.clickable ? 'pointer' : 'default',
    animation: (props: IconProps): string =>
      props.rotating ? '$rotating 2s linear infinite' : 'default',
  },
  '@keyframes rotating': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },
});

export default function Icon(props: IconProps): ReactElement {
  const {
    type,
    className,
    style,
    onClick,
    title,
    testingId,
    rotating,
    clickable,
    ...rest
  } = props;

  const styles = useStyles(props);

  return (
    <i
      className={cx('icon', styles.root, className)}
      style={style}
      title={
        title === false
          ? undefined
          : typeof title === 'string'
          ? title
          : `icon ${type}`
      }
      onClick={onClick}
      data-testing-id={testingId}
      {...rest}
    />
  );
}
