import { BorderRadius, Colors, FontSizes, Spacing } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { ProfilePictureValidationLabels } from './ProfilePictureValidationLabels';

const useStyles = createUseStyles({
  root: {
    marginTop: Spacing.xLarge,
    textAlign: 'center',
    '& h2': {
      fontSize: FontSizes.large,
    },
    '& > p': {
      fontSize: FontSizes.small,
      color: Colors.primary,
    },
  },
  issueList: {
    listStyleType: 'none',
    padding: 0,
  },
  issueListItem: {
    display: 'flex',
    paddingTop: Spacing.medium,
    paddingBottom: Spacing.medium,
    border: `1px solid ${Colors.light}`,
    borderRadius: 4,
    marginBottom: Spacing.small,
  },
  issueListItemNumber: {
    backgroundColor: Colors.tag,
    marginRight: Spacing.medium,
    minWidth: 40,
    height: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: BorderRadius.medium,
  },
  issueListItemDescription: {
    textAlign: 'left',
    '& > strong': {
      marginBottom: 0,
    },
    '& > p': {
      marginTop: Spacing.xsmall,
      marginBottom: 0,
    },
  },
});

interface Props {
  issues: string[];
}

export const ProfilePictureValidation = ({ issues }: Props) => {
  const styles = useStyles();
  if (!issues.length) {
    return <></>;
  }
  return (
    <div className={styles.root}>
      <h2>This image does not meet A.Team's community guidelines</h2>

      <p>
        A high-quality photo is important to maintain trust within the network.
        Please consider the following suggestions to improve your profile
        picture:
      </p>

      <ul className={styles.issueList}>
        {issues.map((issue, idx) => {
          const labels = ProfilePictureValidationLabels[issue];
          if (!labels) return null;
          return (
            <li className={styles.issueListItem} key={issue}>
              <div className={styles.issueListItemNumber}>{idx + 1}</div>
              <div className={styles.issueListItemDescription}>
                <strong>{labels.title}</strong>
                <p>{labels.description}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
