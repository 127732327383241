import { DateISOString } from './misc';
import {
  MissionPaymentCycleId,
  PaymentCycleDates,
} from './MissionPaymentCycleObject';
import { TimesheetRecord } from './TimesheetObject';
import RoleCategoryObject from './RoleCategory';
import { BasicUserObject } from './UserObject';

export type InvoiceId = string;

export enum InvoiceType {
  MissionPaymentCycle = 'MissionPaymentCycle',
  MissionRoleTimesheet = 'MissionRoleTimesheet',
  MissionRolePlatformFee = 'MissionRolePlatformFee',
}

export enum InvoiceStatus {
  /**
   * The initial state for an unpaid invoice.
   * For here invoice can either `Canceled` or move to `Processing` / `Paid`.
   */
  Created = 'Created',

  /**
   * Invoice that got canceled after `Created` without any payment made.
   */
  Canceled = 'Canceled',

  /**
   * The invoice got notified on a planned payment expected and it will move to `Paid` once it received.
   */
  Processing = 'Processing',

  /**
   * The invoice finalized on a `Paid` status after it `Created` or `Processing`.
   */
  Paid = 'Paid',
}

export enum InvoiceCurrency {
  USD = 'USD',
}

export enum InvoiceReceivableSource {
  Brex = 'Brex',
  BankOfAmerica = 'BankOfAmerica',
  Stripe = 'Stripe',
}

export interface BasicInvoiceObject {
  iid: InvoiceId;
  referenceNumber: number;
  purchaseOrderNumber?: string;
  type: InvoiceType;
  status: InvoiceStatus;
  hourlyRate?: number;
  platformFee?: number;
  platformMargin?: number;
  totalAmount: number;
  amountCharged: number;
  chargeFee?: number;
  totalVAT?: number;
  discountAmount?: number;
  discountReason?: string;
  currency: InvoiceCurrency.USD;
  from: InvoiceAddress;
  to: InvoiceAddress;
  downloadURL?: string;
  createdAt: DateISOString;
  issuedAt: DateISOString;
  paymentCycle?: MissionPaymentCycleId;
  paymentTerms?: string;
  paymentDueAt?: DateISOString;
  paidAt?: DateISOString;
  prepaid?: boolean;
}

export interface InvoiceAddress {
  name: string;
  line1?: string;
  line2?: string;
  city?: string;
  postalCode?: string;
  state?: string;
  country?: string;
}

export interface InvoiceBillingDetails {
  customerId: string; // Stripe Customer ID
  bankName: string;
  routingNumber: string;
  accountNumber: string;
  swiftCode: string;
  reference?: string; // A Stripe generated referenced that can be used for stripe automatic reconciliation
  url?: string; // Stripe privides a URL that can be provided to the customer that gives payment information
  /** @deprecated - cannot be used after July 15, 2024 */
  sourceId?: string;
}

export interface MissionPaymentCycleInvoiceObject extends BasicInvoiceObject {
  type: InvoiceType.MissionPaymentCycle;
  period: PaymentCycleDates;
  rolesInvoices: MissionRoleTimesheetInvoiceObject[];
  subtotal: number;
  totalMinutes: number;
  platformFee: number;
  platformMargin: number;
  billingDetails?: InvoiceBillingDetails;
  clientInvoiceRoleDetails: Pick<
    MissionRolePlatformFeeInvoiceObject,
    'rid' | 'totalMinutes' | 'hourlyRate' | 'totalPayments'
  >[];
}

export interface MissionRoleTimesheetInvoiceObject extends BasicInvoiceObject {
  type: InvoiceType.MissionRoleTimesheet;
  period: PaymentCycleDates;
  user: BasicUserObject;
  roleCategory: RoleCategoryObject;
  timesheetRecords: TimesheetRecord[];
  hourlyRate: number;
  totalMinutes: number;
  rid?: string;
}

export interface MissionRolePlatformFeeInvoiceObject
  extends BasicInvoiceObject {
  type: InvoiceType.MissionRolePlatformFee;
  platformMargin: number;
  period: PaymentCycleDates;
  roleCategory: RoleCategoryObject;
  totalMinutes: number;
  hourlyRate: number;
  totalPayments: number;
  rid?: string;
}

export type InvoiceObject =
  | MissionPaymentCycleInvoiceObject
  | MissionRoleTimesheetInvoiceObject
  | MissionRolePlatformFeeInvoiceObject;

// export default InvoiceObject;

export interface PaymentPendingInvoiceReportRow {
  id: string;
  missionTitle: string;
  totalAmount: number;
  totalAdvanced: number;
  daysSinceIssued: number;
  daysOverdue: number;
  billingClientName?: string;
  downloadURL?: string;
  paymentTerms?: string;
}
