import { Button, Column, Icon, Row, Typography } from '@a_team/ui-components';
import {
  BorderColors,
  Breakpoints,
  Colors,
  Spacing,
  Spinner,
  TextColors,
} from '@ateams/components';
import FileUploader from '@src/components/FileUploader';
import Image from '@src/components/Image';
import SuggestionsBanner from '@src/components/SuggestionsBanner';
import MainLayout from '@src/layouts/Main';
import { useStores } from '@src/stores';
import { observer } from 'mobx-react';
import React, { ReactElement, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { ProfilePictureValidation } from './ProfilePictureValidation';
import { useGetProfilePictureAnalysis } from '@src/rq/profile';

const sampleImages = {
  avatar1:
    'https://ucarecdn.com/2446aa09-c51b-4754-978b-3cc9658bcec8/-/preview/999x999/',
  avatar2:
    'https://ucarecdn.com/44de3091-8b19-48ff-873e-ae12e4c83d1e/-/preview/999x999/',
  avatar3:
    'https://ucarecdn.com/3049a857-5b71-4128-adc4-1dea1d819802/-/preview/999x999/',
  avatar4:
    'https://ucarecdn.com/a9e1cec9-5054-42b4-ba37-a0bce72fda25/-/preview/999x999/',
  avatar5:
    'https://ucarecdn.com/d20cf1cf-175c-44e6-bba1-7694c8d3f917/-/preview/999x999/',
  avatar6:
    'https://ucarecdn.com/ae3ab9cf-e6ff-4b8e-92d3-3c2f8a59aa6c/-/preview/999x999/',
  avatar7:
    'https://ucarecdn.com/14fd811f-588c-44d5-a7ee-139e141ecbed/-/preview/999x999/',
};

interface Props {
  withoutMainLayout?: boolean;
  onClose?: () => void;
}

interface AvatarExampleProps {
  url: string;
  valid?: boolean;
}

interface CustomButtonsProps {
  openDialog?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  errors?: string[];
  disabled?: boolean;
}

export interface MissionMatchParams {
  username: string;
}

const useStyles = createUseStyles({
  uploader: {
    width: 300,
    height: '300px !important',
  },
  fileDropArea: {
    background: 'none',
    borderRadius: '50% !important',
    width: 300,
    height: '300px !important',
    textAlign: 'center',
    padding: 0,
    '&.error': {
      borderColor: Colors.primary,
    },
  },
  content: {
    height: '100%',
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
  },
  contentWrapper: {
    color: TextColors.regular,
  },
  close: {
    position: 'absolute',
    right: 16,
    top: 40,
  },
  leftCol: {
    borderBottom: `1px solid ${BorderColors.lighter}`,
    padding: 40,
    marginBottom: 40,
    paddingBottom: 150,
  },
  banner: {
    fontSize: 12,
    color: Colors.secondaryDark,
  },
  avatarRow: {
    display: 'flex',
    gap: 24,
    flexWrap: 'wrap',
  },
  section: {
    marginBottom: 47,
  },
  indication: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: -5,
    top: 15,
  },
  avatar: {
    marginTop: 24,
    borderRadius: '16px !important',
  },
  dragAndDropArea: {
    width: '100%',
    marginTop: 40,
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': {
      fontSize: 14,
      fontWeight: 400,
    },
    '& input[type="file"] + div > div': {
      background: 'none',
      borderRadius: 16,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      backdropFilter: 'none',
      padding: 50,
    },
    '& div[class^="errors"]': {
      display: 'none',
    },
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    close: {
      position: 'absolute',
      right: 16,
      top: 16,
    },
    leftCol: {
      borderRight: `1px solid ${BorderColors.lighter}`,
      borderBottom: 'none',
      paddingBottom: 40,
    },
  },
  loadingPictureAnalysis: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: Spacing.xLarge,
    marginTop: Spacing.xLarge,
  },
});

const AvatarExample = (props: AvatarExampleProps) => {
  const styles = useStyles();
  const { valid = true, url } = props;

  return (
    <span style={{ position: 'relative' }}>
      <Image
        width={100}
        height={100}
        sizes="100px"
        src={url}
        className={styles.avatar}
        alt="avatar"
        aspectRatio={1}
      />
      <span
        className={styles.indication}
        style={{
          background: valid ? Colors.secondaryDark : Colors.primary,
        }}
      >
        <Icon
          size={'md'}
          color={'Grey@0'}
          name={valid ? 'statusPositiveNoBorder' : 'statusNegativeNoBorder'}
        />
      </span>
    </span>
  );
};

const CustomButtons = (props: CustomButtonsProps): ReactElement => {
  const errorsExist = props.errors && props.errors.length > 0;

  if (errorsExist) {
    return (
      <div
        style={{
          textAlign: 'center',
          marginTop: 40,
          marginBottom: 40,
          cursor: 'default',
        }}
      >
        {props.errors?.map((error) => {
          return (
            <Typography
              size={14}
              key={error}
              variant={'textSmall'}
              style={{ display: 'block', color: Colors.primary }}
            >
              {error}
            </Typography>
          );
        })}
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        gap: 8,
        marginTop: 40,
        position: 'absolute',
        cursor: 'default',
        width: '100%',
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Button
        width={'auto'}
        size={'md'}
        variant={'secondary'}
        disabled={props.loading || props.disabled}
        onClick={(e) => {
          props.openDialog && props.openDialog();
        }}
        iconProps={{ name: 'upload' }}
      >
        Upload new
      </Button>
      <Button
        disabled={(props.errors && props.errors.length > 0) || props.disabled}
        width={'auto'}
        size={'md'}
        variant={'main'}
        onClick={(e) => {
          if (errorsExist) return;
          props.onConfirm && props.onConfirm();
        }}
        loading={props.loading}
      >
        Save photo
      </Button>
    </div>
  );
};

const ProfileImageView = ({ withoutMainLayout, onClose }: Props) => {
  const {
    auth,
    users: { profile },
  } = useStores();
  const styles = useStyles();
  const history = useHistory();
  const [tempImage, setTempImage] = useState<string | undefined>(
    auth.user?.profilePictureURL,
  );
  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    !tempImage && setTempImage(auth.user?.profilePictureURL);
  }, [auth.user?.profilePictureURL]);

  const handleSaveImage = () => {
    if (loading) return;

    if (tempImage === auth.user?.profilePictureURL) {
      handleOnClose();
      return;
    }

    setLoading(true);
    if (tempImage) {
      profile?.updateProfileImage(tempImage).then(() => {
        setLoading(false);
        handleOnClose();
      });
    }
  };

  const { data, isLoading } = useGetProfilePictureAnalysis(
    tempImage || '',
    true,
  );

  const handleOnClose = () => {
    if (onClose) {
      onClose();
      return;
    }

    history.goBack();
  };

  const content = () => {
    return (
      <div className={styles.contentWrapper}>
        <Icon
          name={'statusNegativeNoBorder'}
          className={styles.close}
          onClick={handleOnClose}
        />
        <Row style={{ height: withoutMainLayout ? 'auto' : '100%' }}>
          <Column className={styles.leftCol}>
            <Typography
              align={'center'}
              variant={'h2'}
              size={20}
              margin={'xxs'}
            >
              Upload a profile photo
            </Typography>
            <Typography
              align={'center'}
              variant={'textMedium'}
              component={'p'}
              color={'Grey@500'}
            >
              Minimum 1000x1000px and up to 5MB.
              <br />
              PNG and JPEG file formats are supported.
            </Typography>
            <div>
              <div className={styles.dragAndDropArea}>
                <FileUploader
                  hints={[]}
                  fileUrl={tempImage}
                  onFileUploadSuccess={setTempImage}
                  cropAspect={1}
                  type={'default'}
                  withOptionBox={false}
                  withCrop={true}
                  circularCrop={true}
                  inlineCropping
                  maxFileSize={5242880}
                  minFileDimensions={{
                    width: 1000,
                    height: 1000,
                  }}
                  className={styles.uploader}
                  fileDropAreaClassName={styles.fileDropArea}
                  validationElement={
                    isLoading ? (
                      <div className={styles.loadingPictureAnalysis}>
                        <Spinner size={20} color="#6D00D7" />
                      </div>
                    ) : !isUploading && !errors.length ? (
                      <ProfilePictureValidation issues={data?.issues || []} />
                    ) : (
                      <></>
                    )
                  }
                  onFileUploadingChange={(newIsUploading) =>
                    setIsUploading(newIsUploading)
                  }
                  onErrorsChange={(errors) => setErrors(errors)}
                  customButtons={
                    <CustomButtons
                      disabled={isLoading}
                      onConfirm={handleSaveImage}
                      loading={loading}
                    />
                  }
                />
              </div>
            </div>
          </Column>
          <Column style={{ padding: 40 }}>
            <SuggestionsBanner
              className={styles.banner}
              title={'Recommendations for a high quality photo'}
              description={
                'Builders with a quality profile photo are more likely to be selected for missions.'
              }
            />
            <div className={styles.section}>
              <Typography variant={'h3'} margin={'none'}>
                Background
              </Typography>
              <Typography
                variant={'textMedium'}
                component={'p'}
                color={'Grey@500'}
                size={15}
                weight={'light'}
              >
                A light background is often the best choice. If you choose a
                more intricate background, ensure that it is blurred and you
                remain the focal point.
              </Typography>
              <div className={styles.avatarRow}>
                <AvatarExample url={sampleImages.avatar1} />
                <AvatarExample url={sampleImages.avatar2} />
                <AvatarExample url={sampleImages.avatar3} valid={false} />
              </div>
            </div>
            <div className={styles.section}>
              <Typography variant={'h3'} margin={'none'}>
                Lighting Conditions
              </Typography>
              <Typography
                variant={'textMedium'}
                component={'p'}
                color={'Grey@500'}
                size={15}
                weight={'light'}
              >
                Steer clear of accessories like headphones. Also, avoid wearing
                anything that obscures your face, such as hats or sunglasses.
              </Typography>
              <div className={styles.avatarRow}>
                <AvatarExample url={sampleImages.avatar4} />
                <AvatarExample url={sampleImages.avatar5} valid={false} />
              </div>
            </div>
            <div className={styles.section}>
              <Typography variant={'h3'} margin={'none'}>
                Image clarity
              </Typography>
              <Typography
                variant={'textMedium'}
                component={'p'}
                weight={'light'}
                color={'Grey@500'}
                size={15}
              >
                Ensure your picture is of high resolution and sharp.
              </Typography>
              <div className={styles.avatarRow}>
                <AvatarExample url={sampleImages.avatar6} />
                <AvatarExample url={sampleImages.avatar7} valid={false} />
              </div>
            </div>
          </Column>
        </Row>
      </div>
    );
  };

  if (withoutMainLayout) {
    return <>{content()}</>;
  }

  return (
    <MainLayout
      title={'Profile Image'}
      style={{ backgroundColor: 'white' }}
      contentClassName={styles.content}
    >
      {content()}
    </MainLayout>
  );
};

export default observer(ProfileImageView);
