import { action, computed, observable } from 'mobx';
import MissionApplicationObject, {
  AdminMissionApplicationObject,
  ApplicantStatus,
  ApplicationRateRange,
  CustomQuestionReply,
  MissionApplicationDraftObject,
  MissionApplicationId,
  MissionApplicationInternalStatus,
} from '@a_team/models/dist/MissionApplicationObject';
import type {
  MissionApplicationData,
  TeamUpRequestResponse,
  CustomQuestionWithAnswer,
} from '@a_team/models/dist/MissionApplicationObject';
import {
  ApplicationDetails,
  Expertise,
  SkillPreference,
  SkillRequirement,
} from '@src/stores/Profile/models';
import { MissionId } from '@a_team/models/dist/MissionObject';
import MissionRole, {
  ClientRoleQuestion,
  MissionRoleId,
} from '@a_team/models/dist/MissionRole';
import { ExperienceId } from '@a_team/models/dist/ExperienceObject';
import {
  TeammateSuggestionObject,
  UserId,
} from '@a_team/models/dist/UserObject';
import {
  MinimalRecommendationData,
  RecommendationStatus,
} from '@a_team/models/dist/RecommendationObject';
import { Stores } from '@src/stores/index';
import {
  PlatformServiceAnalytics,
  ProfileVersion,
} from '@ateams/analytics/dist/platform';
import {
  AvailabilitySummaryObject,
  AvailableType,
} from '@a_team/models/dist/AvailabilityObject';
import {
  LocalHourRange,
  WorkingHoursSchema,
} from '@a_team/models/dist/WorkingHoursObject';
import { getTimeOverlap } from '@src/helpers/time';
import { getHumanReadableTime } from '@src/views/Profile/Sidebar/WorkingHours/utils';
import { countryListOptionMap } from '@src/helpers/rawCountryList';
import { skillToExpertise } from '@src/helpers/expertise';
import { joinWithCommasAnd } from '@src/logic/utils';
import { getProjectId } from '@src/views/Profile/helpers/experience';
import { uniq, sortBy, invert } from 'lodash';
import { storedApplicationDataSchema } from './utils';
import { z } from 'zod';

export enum ApplicationViewMode {
  View = 'View',
  Edit = 'Edit',
}

export enum ApplicationStep {
  Application = 'Application',
  InterviewAvailability = 'InterViewAvailiability',
  Recommendations = 'Recommendations',
}

enum GuidanceStep {
  NeedsImprovement = 'NeedsImprovement',
  MeetsBasicRequirements = 'MeetsBasicRequirements',
  Solid = 'Solid',
  AboveAverage = 'AboveAverage',
  Impressive = 'Impressive',
}

enum QualityGuidanceField {
  StandOut = 'stand out',
  Rate = 'rate',
  Role = 'role',
  Resume = 'resume',
  Portfolio = 'portfolio',
  Availability = 'availability',
  Projects = 'projects',
  Skills = 'skills',
  Location = 'location',
  WorkingHours = 'working hours overlap',
  CompanyQuestion = 'company question',
}

type FieldCondition = {
  name: QualityGuidanceField;
  condition: boolean;
};

interface SerializedCustomQuestions {
  [key: string]: string;
}

interface StoredApplicationData {
  pitch?: string;
  customQuestions?: SerializedCustomQuestions;
  hourlyRateRange?: ApplicationRateRange;
  monthlyRateRange?: ApplicationRateRange;
  availability?: AvailabilityObject;
  workingHours?: WorkingHoursSchema;
}

export type AvailabilityObject = MissionApplicationData['availability'];

export type guidanceMessages =
  | 'hourlyRate'
  | 'hourlyRateInvalid'
  | 'availabilityValid'
  | 'availabilityInvalid'
  | 'availabilityLow'
  | 'workingHoursOverlapValid'
  | 'workingHoursOverlapInvalid'
  | 'workingHoursOverlapLow'
  | 'roleValid'
  | 'roleInvalid'
  | 'missingSkills'
  | 'requiredSkillsValid'
  | 'requiredSkillsInvalid'
  | 'preferredSkillsValid'
  | 'preferredSkillsInvalid'
  | 'locationValid'
  | 'locationInvalid'
  | 'locationMissing'
  | 'teamUpRequestResponses'
  | 'projectsNotRelevant'
  | 'pitchInvalid'
  | 'clientQuestionInvalid'
  | 'rateAbovePreferredRange';

export interface ApplicationStoreData {
  data:
    | MissionApplicationObject
    | MissionApplicationDraftObject
    | AdminMissionApplicationObject;
  mid?: MissionId;
  rid?: MissionRoleId;
  mode: ApplicationViewMode;
  step: ApplicationStep;
  createdWithMissingRole: boolean;
  pitch?: string;
  about?: string;
  answers: Map<ClientRoleQuestion['qid'], string>;
  teamUpRequestResponses: Map<string, string>;
  submitted: boolean;
  detailsMenuOpen: boolean;
  requirementsModalOpen?: boolean;
  availability: AvailabilityObject;
  hourlyRateRange?: ApplicationRateRange;
  monthlyRateRange?: ApplicationRateRange;
  hiddenProjects: Map<ExperienceId, boolean>;
  workingHours?: WorkingHoursSchema;
  suggestedTeammates: TeammateSuggestionObject[];
  requestedTeammates: MinimalRecommendationData[];
  teammateSearchResults?: TeammateSuggestionObject[];
  showSkipPostApplicationSuggestionsModal?: boolean;
  updateAvailabilityOnSubmit?: boolean;
  updateWorkingHoursOnSubmit?: boolean;
  didGeneratePitchSuggestion?: boolean;
}

export default class Application implements ApplicationStoreData {
  @observable public data: ApplicationStoreData['data'];
  @observable public mode: ApplicationStoreData['mode'] =
    ApplicationViewMode.View;
  @observable public step: ApplicationStoreData['step'] =
    ApplicationStep.Application;
  @observable mid: ApplicationStoreData['mid'];
  @observable rid: ApplicationStoreData['rid'];
  @observable pitch: ApplicationStoreData['pitch'] = '';
  @observable answers: ApplicationStoreData['answers'] = new Map();
  @observable
  teamUpRequestResponses: ApplicationStoreData['teamUpRequestResponses'] = new Map();
  @observable about: ApplicationStoreData['about'] = '';
  @observable submitted: ApplicationStoreData['submitted'] = false;
  @observable detailsMenuOpen: ApplicationStoreData['detailsMenuOpen'] = false;
  @observable
  requirementsModalOpen: ApplicationStoreData['requirementsModalOpen'] = false;
  @observable availability: ApplicationStoreData['availability'] = {
    startDate: new Date().toISOString(),
    hoursPerWeek: 0,
    notes: '',
  };
  @observable
  createdWithMissingRole: ApplicationStoreData['createdWithMissingRole'] = false;
  @observable workingHours: ApplicationStoreData['workingHours'] = undefined;
  @observable hourlyRateRange: ApplicationStoreData['hourlyRateRange'];
  @observable monthlyRateRange: ApplicationStoreData['monthlyRateRange'];
  @observable
  hiddenProjects: ApplicationStoreData['hiddenProjects'] = new Map();
  @observable
  suggestedTeammates: ApplicationStoreData['suggestedTeammates'] = [];
  @observable
  requestedTeammates: ApplicationStoreData['requestedTeammates'] = [];
  @observable
  teammateSearchResults: ApplicationStoreData['teammateSearchResults'];
  @observable
  showSkipPostApplicationSuggestionsModal: ApplicationStoreData['showSkipPostApplicationSuggestionsModal'] =
    false;
  @observable
  updateAvailabilityOnSubmit: ApplicationStoreData['updateAvailabilityOnSubmit'] =
    undefined;
  @observable
  updateWorkingHoursOnSubmit: ApplicationStoreData['updateWorkingHoursOnSubmit'] =
    undefined;
  @observable
  didGeneratePitchSuggestion: ApplicationStoreData['didGeneratePitchSuggestion'] =
    false;

  MIN_REQUIRED_SKILL_RATING = 3;
  PITCH_MIN_LENGTH = 20;
  PITCH_DESIRED_LENGTH = 300;
  REPLY_MIN_LENGTH = 20;
  REPLY_DESIRED_LENGTH = 300;

  private missionsStore?: Stores['missions'];
  private profileStore?: Stores['profile'];
  private analytics: PlatformServiceAnalytics;
  private authStore: Stores['auth'];

  public constructor(
    applicationDetails: ApplicationDetails,
    applicationData: MissionApplicationObject | MissionApplicationDraftObject,
    auth: Stores['auth'],
    analytics: PlatformServiceAnalytics,
    missionsStore?: Stores['missions'],
    profileStore?: Stores['profile'],
    mode?: ApplicationViewMode,
  ) {
    this.missionsStore = missionsStore;
    this.authStore = auth;
    this.analytics = analytics;
    this.mid = applicationDetails.mid;
    this.detailsMenuOpen = false;
    this.profileStore = profileStore;
    this.rid =
      applicationData && 'rid' in applicationData
        ? applicationData.rid
        : applicationDetails.rid;
    this.data = applicationData;
    mode && (this.mode = mode);
    this.setInitialData();
  }

  serialize = (): ApplicationStoreData => {
    return {
      data: this.data,
      mode: this.mode,
      step: this.step,
      mid: this.mid,
      rid: this.rid,
      createdWithMissingRole: this.createdWithMissingRole,
      submitted: this.submitted,
      availability: this.availability,
      workingHours: this.workingHours,
      hourlyRateRange: this.hourlyRateRange,
      hiddenProjects: this.hiddenProjects,
      suggestedTeammates: this.suggestedTeammates,
      requestedTeammates: this.requestedTeammates,
      detailsMenuOpen: this.detailsMenuOpen,
      teamUpRequestResponses: this.teamUpRequestResponses,
      answers: this.answers,
      monthlyRateRange: this.monthlyRateRange,
    };
  };

  setInitialAvailability = (): void => {
    if (!('availability' in this.data)) return;
    this.setAvailability({
      type: AvailableType.Now,
      availableFrom: new Date(this.data.availability.startDate).toISOString(),
      weeklyHoursAvailable: this.data.availability.hoursPerWeek,
      notes: this.data.availability.notes,
    });
  };

  setInitialAnswers = (): void => {
    this.data.customQuestionsReplies?.forEach((reply) =>
      this.setAnswer(reply.qid, reply.text),
    );
  };

  setInitialWorkingHours = (): void => {
    if (!this.data.workingHours) return;
    this.setWorkingHours(this.data.workingHours as WorkingHoursSchema);
  };

  setInitialHourlyRateRange = (): void => {
    if (!this.data.hourlyRateRange) return;
    this.setHourlyRateRange(this.data.hourlyRateRange as ApplicationRateRange);
  };

  setInitialMonthlyRate = (): void => {
    if (!this.data.monthlyRateRange) return;
    this.setMonthlyRateRange(this.data.monthlyRateRange);
  };

  setInitialHiddenProjects = (): void => {
    if (!this.data.hiddenProjects) return;
    this.setHiddenProjects(this.data.hiddenProjects);
  };

  @action setInitialData = (): void => {
    this.setInitialWorkingHours();
    this.setInitialHiddenProjects();
    this.preloadRecommendations();
    this.setCreatedWithMissingRole();
    this.setInitialMonthlyRate();
    this.setAbout(this.data.about);

    if (
      this.rid !== undefined &&
      this.authStore.withSaveApplicationProgress &&
      !this.readonly &&
      !this.aid &&
      typeof window === 'object'
    ) {
      const storedDataString = localStorage.getItem(
        `applicationData_${this.rid}_${this.authStore.uid}`,
      );

      if (storedDataString !== null) {
        const storedData: StoredApplicationData =
          storedApplicationDataSchema.parse(JSON.parse(storedDataString));

        this.setPitch(storedData.pitch || this.data.pitch);

        if (storedData.hourlyRateRange) {
          this.setHourlyRateRange(storedData.hourlyRateRange);
        } else {
          this.setInitialHourlyRateRange();
        }

        if (storedData.monthlyRateRange) {
          this.setMonthlyRateRange(storedData.monthlyRateRange);
        } else {
          this.setInitialMonthlyRate();
        }

        if (storedData.availability) {
          this.setAvailability({
            availableFrom: storedData.availability.startDate.toString(),
            weeklyHoursAvailable: storedData.availability.hoursPerWeek,
            notes: storedData.availability.notes,
            type: AvailableType.Now,
          });
        } else {
          this.setInitialAvailability();
        }

        if (storedData.workingHours) {
          this.setWorkingHours(storedData.workingHours);
        } else {
          this.setInitialWorkingHours();
        }

        if (storedData.customQuestions) {
          const customQuestionsMap = new Map<string, string>();
          Object.entries(storedData.customQuestions).forEach(
            ([qid, answer]) => {
              customQuestionsMap.set(qid, answer);
            },
          );

          this.answers = customQuestionsMap;
        } else {
          this.setInitialAnswers();
        }
      } else {
        this.setPitch(this.data.pitch);
        this.setInitialAnswers();
        this.setInitialHourlyRateRange();
        this.setInitialMonthlyRate();
        this.setInitialAvailability();
        this.setInitialWorkingHours();
      }
    } else {
      this.setPitch(this.data.pitch);
      this.setInitialAnswers();
      this.setInitialHourlyRateRange();
      this.setInitialMonthlyRate();
      this.setInitialAvailability();
      this.setInitialWorkingHours();
    }
  };

  skipRecommendations = (): void => {
    this.setSubmitted(true);
    this.trackSkipRecommendations();
  };

  gotoInterviewAvailability = (): void => {
    this.setApplicationStep(ApplicationStep.InterviewAvailability);
  };

  gotoRecommendations = (): void => {
    this.setApplicationStep(ApplicationStep.Recommendations);
    this.trackViewRecommendations();
  };

  preloadRecommendations = (): void => {
    if (!this.shouldSkipApplicationRecommendations)
      this.getTeammateSuggestions();
  };

  getTeammateSuggestions = async (): Promise<void> => {
    if (!this.rid || !this.missionsStore) return;
    const suggestions = await this.missionsStore.getTeammateSuggestions(
      this.rid,
    );
    this.setSuggestedTeammates(suggestions.items);
  };

  searchTeammateSuggestions = async (query: string): Promise<void> => {
    if (!this.rid || !this.missionsStore) return;
    if (query === '') {
      this.setTeammateSearchResults(undefined);
      return;
    }

    await this.missionsStore
      .searchTeammateSuggestions(this.rid, query)
      .then((res) => {
        this.setTeammateSearchResults(res.items);
      });

    this.trackSearchRecommendations(query);
  };

  submitRecommendations = async (): Promise<void> => {
    if (!this.aid) return;
    if (this.requestedUsers.length === 0) {
      this.skipRecommendations();
      return;
    }

    await this.missionsStore?.submitTeammateRecommendations(
      this.aid,
      this.requestedTeammates,
    );

    this.setSubmitted(true);
    this.setMode(ApplicationViewMode.View);
    this.trackRecommendationsSubmit();
  };

  trackRecommendationsSubmit = (): void => {
    if (!this.aid || !this.missionsStore?.currentMission || !this.currentRole)
      return;

    this.analytics.trackTeammateRecommendationsSubmitted(
      this.requestedTeammates,
      this.missionsStore.currentMission?.data,
      this.currentRole,
      ProfileVersion.New,
    );
  };

  trackViewRecommendations = (): void => {
    if (!this.missionsStore?.currentMission || !this.currentRole) return;

    this.analytics.trackTeammateRecommendationsViewed(
      this.missionsStore.currentMission.data,
      this.currentRole,
      ProfileVersion.New,
    );
  };

  trackSkipRecommendations = (): void => {
    if (!this.missionsStore?.currentMission || !this.currentRole) return;

    this.analytics.trackTeammateRecommendationsSkipButtonClick(
      this.missionsStore.currentMission.data,
      this.currentRole,
      ProfileVersion.New,
    );
  };

  trackSearchRecommendations = (query: string): void => {
    if (!this.missionsStore?.currentMission || !this.currentRole) return;
    this.analytics.trackTeammateRecommendationsSearch(
      this.missionsStore?.currentMission.data,
      this.currentRole,
      query,
      ProfileVersion.New,
    );
  };

  trackApplicationSubmit = (): void => {
    if (!this.missionsStore?.currentMission || !this.currentRole) return;

    this.analytics.trackMissionApplySubmitted(
      {
        ...this.missionsStore.currentMission.data,
        role: this.currentRole,
      },
      this.data as MissionApplicationData,
      ProfileVersion.New,
      this.missionsStore.currentMission.isRecommended,
    );
  };

  @computed get readonly(): boolean {
    return this.mode === ApplicationViewMode.View;
  }

  @computed get isRecommendationsStep(): boolean {
    return this.step === ApplicationStep.Recommendations;
  }

  @computed get isInterviewAvailabilityStep(): boolean {
    return this.step === ApplicationStep.InterviewAvailability;
  }

  @computed get shouldSkipApplicationRecommendations(): boolean {
    return (
      this.authStore.withSkipTeamUp ||
      this.missionsStore?.currentMission?.openRoleTitles.length === 1 ||
      !!this.aid
    );
  }

  @computed get aid(): MissionApplicationId | undefined {
    return 'aid' in this.data ? this.data.aid : undefined;
  }

  @computed get minHourlyRate(): number {
    return this.hourlyRateRange?.min || 0;
  }

  @computed get maxHourlyRate(): number {
    return this.hourlyRateRange?.max || 0;
  }

  @computed get monthlyRateRangeMin(): number {
    return this.monthlyRateRange?.min ?? 0;
  }

  @computed get monthlyRateRangeMax(): number {
    return this.monthlyRateRange?.max ?? 0;
  }

  @computed get hasMonthlyRateError(): boolean {
    const monthlyRate = this.monthlyRateRange?.min ?? 0;

    // if a builder doesn't have at least 40 hours, we let them submit regardless
    if (this.availability.hoursPerWeek < 40) {
      return false;
    }

    if (this.currentRole?.collectMonthlyRate && monthlyRate === 0) {
      return true;
    }

    return false;
  }

  @computed get hasHourlyRateError(): boolean {
    return (
      !this.minHourlyRate ||
      !this.maxHourlyRate ||
      this.minHourlyRate > this.maxHourlyRate
    );
  }

  @computed get hourlyRateInRoleRange(): boolean {
    if (
      !this.currentRole?.builderRateMin ||
      !this.currentRole?.builderRateMax ||
      !this.hourlyRateRange?.min ||
      !this.hourlyRateRange?.max
    )
      return true;

    const { builderRateMax, builderRateMin } = this.currentRole;
    const roleMin = this.hourlyRateRange?.min;
    const roleMax = this.hourlyRateRange?.max;

    if (builderRateMin < roleMin) {
      return builderRateMax > roleMin;
    } else {
      return builderRateMin < roleMax;
    }
  }

  @computed get hourlyRateBelowRange(): boolean {
    if (this.hasHourlyRateError) {
      return true;
    }

    if (
      !this.currentRole?.builderRateMin ||
      !this.currentRole?.builderRateMax ||
      !this.hourlyRateRange?.min ||
      !this.hourlyRateRange?.max ||
      this.hourlyRateInRoleRange
    )
      return false;

    const { builderRateMin: roleRateMin } = this.currentRole;
    const builderRateMin = this.hourlyRateRange?.min;
    const builderRateMax = this.hourlyRateRange?.max;

    if (builderRateMin < roleRateMin) {
      return builderRateMax < roleRateMin;
    } else {
      return false;
    }
  }

  @computed get availabilityNotMatchingRole(): boolean {
    if (
      !this.currentRole?.availability ||
      this.availability.hoursPerWeek === undefined
    )
      return false;
    return (
      this.currentRole?.availability.weeklyHoursAvailable >
      this.availability.hoursPerWeek
    );
  }

  @computed get hasTeamUpRequestResponsesError(): boolean {
    if (!this.teamUpRequestResponses?.size) {
      return false;
    }

    return Array.from(this.teamUpRequestResponses.values()).some(
      (value) => value === RecommendationStatus.Pending,
    );
  }

  @computed get hasPitchError(): boolean {
    return !this.pitch || this.pitch.length < this.PITCH_MIN_LENGTH;
  }

  @computed get hasCustomQuestionReplyError(): CustomQuestionReply['qid'][] {
    return this.answersArray
      .filter(
        (answer) =>
          answer.text.trim().length > 0 &&
          answer.text.trim().length < this.REPLY_MIN_LENGTH,
      )
      .map((answer) => answer.qid);
  }

  @computed get hasAvailabilityError(): boolean {
    return (
      !this.availability.hoursPerWeek || this.availability.hoursPerWeek > 168
    );
  }

  @computed get isUserMissingRole(): boolean {
    if (!this.currentRole?.category || !this.profileStore) return true;
    const { allRoles } = this.profileStore;
    const roleIds = allRoles.map((role) => role.id);
    return !roleIds.includes(this.currentRole.category.cid);
  }

  @computed get guidanceMessages(): Record<guidanceMessages, string> {
    return {
      hourlyRate:
        'If your rate is above the preferred range, you can still apply, but your chances of being selected are lower.',
      hourlyRateInvalid:
        'Your rate is above the preferred range, you can still apply, but your chances of being selected are lower.',
      availabilityValid: `You’re available ${this.availability.hoursPerWeek} hours per week`,
      availabilityInvalid: 'You don’t have enough availability',
      availabilityLow: `Increase your availability by ${
        (this.currentRole?.availability?.weeklyHoursAvailable || 0) -
        this.availability.hoursPerWeek
      } hours to meet the company’s requirement`,
      workingHoursOverlapValid: `You have ${this.hoursOverlapInWords} overlap`,
      workingHoursOverlapInvalid: `${
        this.hoursOverlapWithRoleInMinutes === 0
          ? 'You have no overlap'
          : `You have ${this.hoursOverlapInWords}`
      }. Adjust your working hours to meet the requirement.`,
      workingHoursOverlapLow: `Add ${getHumanReadableTime(
        (this.currentRole?.workingHours?.numberOfMinutesOverlap || 0) -
          (this.hoursOverlapWithRoleInMinutes || 0),
      )} of overlap to meet the company’s requirement`,
      roleValid: 'Your role matches the requirements.',
      roleInvalid:
        'Your roles don’t match the requirement. If you have the appropriate skills and experience, add a role to be considered.',
      requiredSkillsValid: 'You have all required skills.',
      requiredSkillsInvalid: this.isUserMissingRole
        ? `Your role doesn't match the requirements. To include skills, add a ${
            this.currentRole?.category.title || ''
          } role.`
        : `You're missing ${this.missingRequiredSkills.length} of ${
            this.requiredSkillsEvaluation?.length
          } required skills${
            this.lowProficiencyRequiredSkills.length
              ? ` and ${this.lowProficiencyRequiredSkills.length} is rated below 3`
              : ''
          }. Please update if you have the appropriate proficiency.`,
      preferredSkillsValid: 'You have all preferred skills.',
      preferredSkillsInvalid: this.isUserMissingRole
        ? `Your role doesn't match the requirements. To include skills, add a ${
            this.currentRole?.category.title || ''
          } role.`
        : `You are missing ${this.missingPreferredSkills.length} of ${this.preferredSkillsEvaluation?.length} preferred skills. Please update if you have the appropriate proficiency.`,
      missingSkills:
        'If you have the relevant experience, adjust the skills to have a score of 3 or above',
      locationValid: "Your location is within the company's requirements.",
      locationInvalid:
        "Your location is not within the company's requirements, you can still apply, but your chances of being selected are lower.",
      locationMissing:
        "You haven’t set a location. Add where you'll be physically located while working on missions.",
      teamUpRequestResponses:
        'You must respond to the pending invites before submitting the application.',
      projectsNotRelevant: this.projectsGuidanceMessage,
      pitchInvalid: `Demonstrate your compatibility with the mission by writing a response of at least 300 characters.`,
      clientQuestionInvalid: `Address the company’s question by writing a thorough response of at least 300 characters.`,
      rateAbovePreferredRange: `Your rate is above the preferred range, you can still apply, but your chances of being selected are lower.`,
    };
  }

  @computed get showProjectsGuidanceMessage(): boolean {
    if (!this.authStore.withApplicationBuilderScoring) return false;

    const selectedProjects =
      this.profileStore?.localHiddenAndSelectedProjects.selected || [];

    return (
      !this.selectedProjectsHaveMissionIndustry ||
      !this.selectedProjectsHaveRequiredSkills ||
      !this.selectedProjectsHavePreferredSkills ||
      !this.selectedProjectsHaveRole ||
      selectedProjects.length < 3
    );
  }

  @computed get showSkillsGuidanceMessage(): boolean {
    if (!this.authStore.withApplicationBuilderScoring) return false;

    return (
      !!this.missingRequiredSkills.length ||
      !!this.missingPreferredSkills.length ||
      !!this.requiredSkillsEvaluation?.some(
        (skill) => !skill.rating || skill.rating < 3,
      ) ||
      !!this.preferredSkillsEvaluation?.some(
        (skill) => !skill.rating || skill.rating < 3,
      )
    );
  }

  @computed get showPitchGuidanceMessage(): boolean {
    if (!this.authStore.withApplicationBuilderScoring) return false;
    return !this.pitch || this.pitch.length < this.PITCH_DESIRED_LENGTH;
  }

  @computed get showClientQuestionGuidanceMessage(): boolean {
    if (!this.authStore.withApplicationBuilderScoring) return false;
    return (
      !!this.answersArray.find(
        (answer) =>
          answer.text.length === 0 ||
          answer.text.length < this.REPLY_DESIRED_LENGTH,
      ) || this.answersArray.length === 0
    );
  }

  @computed get showHourlyRateGuidanceMessage(): boolean {
    if (!this.authStore.withApplicationBuilderScoring) return false;
    return !this.hourlyRateInRoleRange && !this.hourlyRateBelowRange;
  }

  @computed get showAvailabilityGuidanceMessage(): boolean {
    if (!this.authStore.withApplicationBuilderScoring) return false;
    return this.availabilityNotMatchingRole;
  }

  @computed get showWorkingHoursOverlapGuidanceMessage(): boolean {
    if (!this.authStore.withApplicationBuilderScoring) return false;
    return this.workingHoursOverlapTooLow;
  }

  @computed get projectsGuidanceMessage(): string {
    const message =
      this.profileStore?.localHiddenAndSelectedProjects.selected.length === 3
        ? 'Revise your selection to showcase more relevant experience'
        : 'Include three relevant projects to stand out';

    const missingSkillsString = `${joinWithCommasAnd(
      [
        ...(this.selectedProjectsMissingRequiredSKills || []),
        ...(this.selectedProjectsMissingPreferredSKills || []),
      ].map((skill) => skill.talentSkillName || ''),
      'or',
    )}`;

    const industryName = this.missionsStore?.currentMission?.data.industries
      ? joinWithCommasAnd(
          this.missionsStore?.currentMission?.data.industries.map(
            (industry) => industry.name,
          ),
          'or',
        )
      : "company's";
    const roleName = this.currentRole?.category.title;

    if (
      !this.selectedProjectsHaveRole &&
      (!this.selectedProjectsHaveRequiredSkills ||
        !this.selectedProjectsHavePreferredSkills) &&
      !this.selectedProjectsHaveMissionIndustry
    ) {
      return `${message}. Highlight your experience in the ${industryName} industry and your role as a ${roleName}. Also, demonstrate how you’ve used skills like ${missingSkillsString}.`;
    }

    if (
      !this.selectedProjectsHaveRole &&
      !this.selectedProjectsHaveMissionIndustry
    ) {
      return `${message}. Highlight your experience in the  ${industryName} industry and your role as a ${roleName}.`;
    }

    if (
      !this.selectedProjectsHaveRole &&
      (!this.selectedProjectsHaveRequiredSkills ||
        !this.selectedProjectsHavePreferredSkills)
    ) {
      return `${message}. Highlight your role as a ${roleName}. Also, demonstrate how you’ve used skills like ${missingSkillsString}.`;
    }
    if (!this.selectedProjectsHaveRole) {
      return `${message}. Highlight your role as a ${roleName}.`;
    }

    if (
      (!this.selectedProjectsHaveRequiredSkills ||
        !this.selectedProjectsHavePreferredSkills) &&
      !this.selectedProjectsHaveMissionIndustry
    ) {
      return `${message}. Highlight your experience in the ${industryName} industry. Also, demonstrate how you’ve used skills like ${missingSkillsString}.`;
    }

    if (!this.selectedProjectsHaveMissionIndustry) {
      return `${message}. Highlight your experience in the ${industryName} industry.`;
    }

    if (
      !this.selectedProjectsHaveRequiredSkills ||
      !this.selectedProjectsHavePreferredSkills
    ) {
      return `${message}. Demonstrate how you’ve used skills like ${missingSkillsString}.`;
    }

    return `${message}.`;
  }

  @computed get isApplicationValid(): boolean {
    return (
      !this.hasHourlyRateError &&
      !this.hasTeamUpRequestResponsesError &&
      !this.hasPitchError &&
      !this.hasAvailabilityError
    );
  }

  @computed get roleMarginPercent(): number | undefined {
    if (!this.missionsStore?.currentMission?.data) return undefined;
    return 'rolesMargin' in this.missionsStore?.currentMission?.data
      ? this.missionsStore?.currentMission?.data.rolesMargin * 100
      : undefined;
  }

  @computed get isDirty(): boolean {
    return (
      this.didPitchChange ||
      this.didRateChange ||
      this.didAvailabilityChange ||
      this.didWorkingHoursChange ||
      this.didCustomQuestionsRepliesChange
    );
  }

  @computed get didPitchChange(): boolean {
    return this.pitch !== this.data.pitch;
  }

  @computed get didRateChange(): boolean {
    return (
      this.data.hourlyRateRange?.min !== this.minHourlyRate ||
      this.data.hourlyRateRange.max !== this.maxHourlyRate
    );
  }

  @computed get didAvailabilityChange(): boolean {
    return (
      this.availability.notes !== (this.data.availability.notes || '') ||
      this.availability.hoursPerWeek !== this.data.availability.hoursPerWeek ||
      this.availability.startDate !== this.data.availability.startDate
    );
  }

  @computed get didWorkingHoursChange(): boolean {
    return (
      JSON.stringify(this.workingHours) !==
      JSON.stringify(this.data.workingHours)
    );
  }

  @computed get didCustomQuestionsRepliesChange(): boolean {
    if ((this.currentRole?.customQuestions || []).length === 0) {
      return false;
    }

    return (
      JSON.stringify(this.answersArray) !==
      JSON.stringify(this.data.customQuestionsReplies || [])
    );
  }

  @computed get answersArray(): CustomQuestionReply[] {
    return Array.from(this.answers, (entry) => {
      return { qid: entry[0], text: entry[1] };
    });
  }

  @computed get teamUpRequestResponsesArray(): TeamUpRequestResponse[] {
    return Array.from(this.teamUpRequestResponses, (entry) => {
      return { rid: entry[0], status: entry[1] };
    });
  }

  @computed get customQuestionsWithAnswers(): CustomQuestionWithAnswer[] {
    if (this.answersArray.length === 0) {
      return [];
    }

    return this.answersArray.map((answer) => ({
      question:
        this.currentRole?.customQuestions?.find((q) => q.qid === answer.qid)
          ?.text ?? '',
      answer: answer.text,
    }));
  }

  @computed get currentRole(): MissionRole | undefined {
    return this.missionsStore?.currentMission?.data.roles.find(
      (role) => role.rid === this.rid,
    );
  }

  @computed get requiredCustomQuestions(): ClientRoleQuestion[] {
    return (this.currentRole?.customQuestions || []).filter(
      (question) => !!question.isRequired,
    );
  }

  @computed get optionalCustomQuestions(): ClientRoleQuestion[] {
    return (this.currentRole?.customQuestions || []).filter(
      (question) => !question.isRequired,
    );
  }

  @computed get customQuestionsIdsWithErrors(): CustomQuestionReply['qid'][] {
    const customQuestions = this.currentRole?.customQuestions || [];
    return customQuestions
      .filter((customQuestion) => {
        const answer = this.answers.get(customQuestion.qid)?.trim() ?? '';
        return (
          // Apply the condition where answers must be at least this.REPLY_MIN_LENGTH
          // characters long if the question is required or if it is optional but has
          // at least one character.
          (customQuestion.isRequired || answer.length > 0) &&
          answer.length < this.REPLY_MIN_LENGTH
        );
      })
      .map((customQuestion) => customQuestion.qid);
  }

  @computed get missingRequiredCustomQuestions(): ClientRoleQuestion[] {
    return this.requiredCustomQuestions.filter(
      (question) =>
        !this.answers.has(question.qid) ||
        this.answers.get(question.qid)?.length === 0 ||
        this.hasCustomQuestionReplyError.includes(question.qid),
    );
  }

  @computed get missingOptionalCustomQuestions(): ClientRoleQuestion[] {
    return this.optionalCustomQuestions.filter(
      (question) =>
        !this.answers.has(question.qid) ||
        this.answers.get(question.qid)?.length === 0 ||
        this.hasCustomQuestionReplyError.includes(question.qid),
    );
  }

  @computed get missionRoles(): MissionRole[] | undefined {
    return this.missionsStore?.currentMission?.data.roles;
  }

  @computed get requiredSkillsEvaluation(): SkillRequirement[] | undefined {
    if (!this.currentRole?.requiredSkills) return undefined;

    return this.currentRole?.requiredSkills.map((requiredSkill) => {
      const profileSkill = this.profileStore?.allSkills.find(
        (profSkill) => profSkill.id === requiredSkill.talentSkillId,
      );
      return {
        ...requiredSkill,
        rating: profileSkill?.rating || 0,
        inRequiredRating: profileSkill?.rating
          ? profileSkill.rating >= this.MIN_REQUIRED_SKILL_RATING
          : false,
        missing: !profileSkill,
      };
    });
  }

  @computed get missingRequiredSkills(): SkillRequirement[] {
    return (this.requiredSkillsEvaluation || []).filter(
      (skill) => !!skill.missing,
    );
  }

  @computed get userRequiredSkills(): string[] {
    const requiredSkills = (this.requiredSkillsEvaluation || []).filter(
      (skill) => !skill.missing && skill.inRequiredRating,
    );

    return requiredSkills.map((skill) => skill.talentSkillName ?? '');
  }

  @computed get userPrefferedSkills(): string[] {
    const preferredSkills = (this.preferredSkillsEvaluation || []).filter(
      (skill) => !skill.missing,
    );

    return preferredSkills.map((skill) => skill.talentSkillName ?? '');
  }

  @computed get missingSkillsExpertise(): Expertise[] {
    const lowProfUserSKills = this.lowProficiencyRequiredSkills.map((skill) => {
      const userSkill = this.profileStore?.allSkills.find(
        (item) => item.id === skill.talentSkillId,
      );
      return userSkill || skillToExpertise(skill);
    });

    return [
      ...this.missingRequiredSkills.map((skill) => skillToExpertise(skill)),
      ...lowProfUserSKills,
    ];
  }

  @computed get missingPreferredSkills(): SkillPreference[] {
    return (this.preferredSkillsEvaluation || []).filter(
      (skill) => !!skill.missing,
    );
  }

  @computed get missingPreferredSkillsExpertise(): Expertise[] {
    return this.missingPreferredSkills.map((skill) => skillToExpertise(skill));
  }

  @computed get missingRole(): string | undefined {
    return this.isUserMissingRole
      ? this.currentRole?.category.title
      : undefined;
  }

  @computed get lowProficiencyRequiredSkills(): SkillRequirement[] {
    return (this.requiredSkillsEvaluation || []).filter(
      (skill) => !skill.inRequiredRating && !skill.missing,
    );
  }

  @computed get preferredSkillsEvaluation(): SkillPreference[] | undefined {
    if (!this.currentRole?.preferredSkills) return undefined;

    return this.currentRole?.preferredSkills.map((preferredSkill) => {
      const profileSkill = this.profileStore?.allSkills.find(
        (profSkill) => profSkill.id === preferredSkill.talentSkillId,
      );
      return {
        ...preferredSkill,
        rating: profileSkill?.rating || 0,
        missing: !profileSkill,
      };
    });
  }

  @computed get missingRequirements(): boolean {
    if (this.hasMonthlyRateError) {
      return false;
    }

    return (
      this.isUserMissingRole ||
      (this.missingRequiredSkills && this.missingRequiredSkills.length > 0) ||
      (this.lowProficiencyRequiredSkills &&
        this.lowProficiencyRequiredSkills.length > 0) ||
      this.missingRequiredCustomQuestions.length > 0
    );
  }

  @computed get missingPreferredRequirements(): boolean {
    return (
      (this.missingPreferredSkills && this.missingPreferredSkills.length > 0) ||
      this.workingHoursOverlapTooLow ||
      this.availabilityNotMatchingRole ||
      !this.userLocationInRoleReqs ||
      this.missingOptionalCustomQuestions.length > 0
    );
  }

  @computed get shouldShowRequirementsMissingWarning(): boolean {
    return this.missingRequirements || this.missingPreferredRequirements;
  }

  @computed get showQualityGuidance(): boolean {
    return (
      !!this.authStore.withApplicationBuilderScoring &&
      !!this.profileStore?.isCurrentUser &&
      !this.readonly
    );
  }

  @computed get hoursOverlapWithRoleInMinutes(): number | undefined {
    const userDaily = this.workingHours?.daily as LocalHourRange[];
    const userTimezoneName = this.profileStore?.data.timezone?.name;
    const roleDaily = this.currentRole?.workingHours?.daily as LocalHourRange[];
    const roleTimezoneName = this.currentRole?.workingHours?.name;
    if (
      userDaily === undefined ||
      userDaily.length === 0 ||
      userTimezoneName === undefined ||
      roleDaily === undefined ||
      roleDaily.length === 0 ||
      roleTimezoneName === undefined
    ) {
      return undefined;
    }

    const overlap = getTimeOverlap(
      userDaily,
      userTimezoneName,
      roleDaily,
      roleTimezoneName,
    );
    return overlap.overlappedTimeInMinutes;
  }

  @computed get hoursOverlapInWords(): string {
    return getHumanReadableTime(this.hoursOverlapWithRoleInMinutes || 0);
  }

  @computed get workingHoursOverlapTooLow(): boolean {
    const numberOfMinutesOverlap =
      this.currentRole?.workingHours?.numberOfMinutesOverlap;
    return (
      this.hoursOverlapWithRoleInMinutes !== undefined &&
      !!numberOfMinutesOverlap &&
      numberOfMinutesOverlap > this.hoursOverlapWithRoleInMinutes
    );
  }

  @computed get userLocationInRoleReqs(): boolean {
    if (
      !this.currentRole?.locations ||
      this.currentRole.locations.length === 0
    ) {
      return true;
    }
    const roleLocations =
      this.currentRole?.locations?.filter(
        (code: string) => !!countryListOptionMap[code],
      ) ?? [];

    if (!this.profileStore?.countryRawObject) {
      return false;
    }

    return roleLocations.indexOf(this.profileStore?.countryRawObject.code) > -1;
  }

  public async updateApplicationRole(
    aid: string,
    rid: string,
  ): Promise<AdminMissionApplicationObject | undefined> {
    const application = await this.missionsStore?.updateApplicationRole(
      aid,
      rid,
    );
    if (application && this.mid) {
      this.profileStore?.setApplication(
        {
          aid: application.aid,
          rid: application.rid,
          mid: this.mid,
        },
        application,
      );
      this.setApplicationData(application);
    }
    return application;
  }

  @computed get applicationData(): MissionApplicationData {
    return {
      pitch: this.pitch || '',
      about: this.about || this.profileStore?.aboutMe || '',
      availability: this.availability,
      hiddenProjects: Array.from(this.hiddenProjects.keys()),
      hourlyRateRange: this.hourlyRateRange,
      workingHours: this.workingHours ?? this.data.workingHours,
      customQuestionsReplies: this.answersArray,
      teamUpRequestResponses: this.teamUpRequestResponsesArray,
      changes: this.data.changes,
      monthlyRateRange: this.monthlyRateRange,
    };
  }

  @computed get requestedUsers(): TeammateSuggestionObject[] {
    const users: TeammateSuggestionObject[] = [];
    this.requestedTeammates.forEach((teammate) => {
      const user = this.suggestedTeammates
        .concat(this.teammateSearchResults || [])
        .find((user) => teammate.userId === user.uid);
      user && users.push(user);
    });
    return users;
  }

  @computed get statusData(): ApplicantStatus | undefined {
    if (!('internalStatus' in this.data)) return undefined;
    return {
      reviewStatus: this.data.reviewStatus,
      exclusiveStatus: this.data.exclusiveStatus,
      internalStatus:
        this.data.internalStatus in MissionApplicationInternalStatus
          ? this.data.internalStatus
          : MissionApplicationInternalStatus.New,
      proposedToClient: this.data.proposedToClient,
      lowCompetitiveness: this.data.lowCompetitiveness,
      rejectionReason: this.data.rejectionReason,
      accepted: this.data.accepted,
      proposal: this.data.proposal,
      proposalInterviewing: this.data.proposalInterviewing,
    };
  }

  @computed get showRole(): boolean {
    return (
      !this.readonly &&
      !!this.currentRole &&
      !!this.profileStore?.isCurrentUser &&
      this.createdWithMissingRole
    );
  }

  @computed get showCustomRoleQuestions(): boolean {
    if (!this.currentRole) {
      return false;
    }

    return (this.currentRole?.customQuestions || []).length > 0;
  }

  @computed get selectedProjectsHaveRequiredSkills(): boolean {
    return this.selectedProjectsMissingRequiredSKills.length === 0;
  }

  @computed get selectedProjectsMissingRequiredSKills(): SkillRequirement[] {
    if (
      !this.requiredSkillsEvaluation ||
      this.requiredSkillsEvaluation.length === 0
    )
      return [];

    const selectedProjects =
      this.profileStore?.localHiddenAndSelectedProjects.selected || [];
    const requiredSkillIds = this.requiredSkillsEvaluation;
    const missingSkills: SkillRequirement[] = [];

    requiredSkillIds.forEach((skill) => {
      const project = (selectedProjects || []).find((selectedProjectId) => {
        const projectData = this.profileStore?.projects.find(
          (project) => getProjectId(project) === selectedProjectId,
        );
        return projectData?.skills?.includes(skill.talentSkillId);
      });
      if (!project) {
        missingSkills.push(skill);
      }
    });
    return missingSkills;
  }

  @computed get selectedProjectsHavePreferredSkills(): boolean {
    return this.selectedProjectsMissingPreferredSKills.length === 0;
  }

  @computed get selectedProjectsMissingPreferredSKills(): SkillPreference[] {
    if (
      !this.preferredSkillsEvaluation ||
      this.preferredSkillsEvaluation.length === 0
    )
      return [];

    const selectedProjects =
      this.profileStore?.localHiddenAndSelectedProjects.selected || [];
    const preferredSkillIds = this.preferredSkillsEvaluation;
    const missingSkills: SkillPreference[] = [];

    preferredSkillIds.forEach((skill) => {
      const project = (selectedProjects || []).find((selectedProjectId) => {
        const projectData = this.profileStore?.projects.find(
          (project) => getProjectId(project) === selectedProjectId,
        );
        return projectData?.skills?.includes(skill.talentSkillId);
      });
      if (!project) {
        missingSkills.push(skill);
      }
    });
    return missingSkills;
  }

  @computed get selectedProjectsHaveMissionIndustry(): boolean {
    if (
      !this.missionsStore?.currentMission?.data.industries ||
      this.missionsStore?.currentMission?.data.industries.length === 0
    ) {
      return true;
    }

    const selectedProjects =
      this.profileStore?.localHiddenAndSelectedProjects.selected || [];

    const projectIndustry =
      this.missionsStore?.currentMission?.data.industries.find((industry) => {
        return selectedProjects.find((selectedProjectId) => {
          const projectData = this.profileStore?.projects.find(
            (project) => getProjectId(project) === selectedProjectId,
          );

          if (this.authStore.withMultipleIndustriesForExperiences) {
            return projectData?.industries?.find(
              (projectDataIndustry) => projectDataIndustry === industry.id,
            );
          } else {
            return projectData?.industry === industry.id;
          }
        });
      });

    return !!projectIndustry;
  }

  @computed get selectedProjectsHaveRole(): boolean {
    const selectedProjects =
      this.profileStore?.localHiddenAndSelectedProjects.selected || [];

    const roleMatches = selectedProjects.find((projectId) => {
      const projectData = this.profileStore?.projects.find(
        (project) => getProjectId(project) === projectId,
      );
      return projectData?.jobRoleId === this.currentRole?.category.cid;
    });
    return !!roleMatches;
  }

  @computed get qualityGuidanceStep(): GuidanceStep {
    if (this.meetsBasicRequirementsInvalidFields.length > 0) {
      return GuidanceStep.NeedsImprovement;
    }
    if (this.solidApplicationGuidanceInvalidFields.length > 0) {
      return GuidanceStep.MeetsBasicRequirements;
    }
    if (this.aboveAverageGuidanceInvalidFields.length > 0) {
      return GuidanceStep.Solid;
    }
    if (this.impressiveApplicationGuidanceInvalidFields.length > 0) {
      return GuidanceStep.AboveAverage;
    }

    return GuidanceStep.Impressive;
  }

  @computed get impressiveApplicationGuidanceInvalidFields(): FieldCondition[] {
    const fields: FieldCondition[] = [
      {
        name: QualityGuidanceField.Rate,
        condition: !this.hourlyRateInRoleRange && !this.hourlyRateBelowRange,
      },
      {
        name: QualityGuidanceField.Projects,
        condition:
          !this.selectedProjectsHaveRequiredSkills ||
          !this.selectedProjectsHavePreferredSkills,
      },
    ];

    return fields.filter((field) => !!field.condition);
  }

  @computed get aboveAverageGuidanceInvalidFields(): FieldCondition[] {
    const fields: FieldCondition[] = [
      {
        name: QualityGuidanceField.StandOut,
        condition: !!this.pitch && this.pitch.length < 300,
      },
      {
        name: QualityGuidanceField.Skills,
        condition: !this.preferredSkillsEvaluation?.every(
          (skill) =>
            skill.rating && skill.rating >= this.MIN_REQUIRED_SKILL_RATING,
        ),
      },
      {
        name: QualityGuidanceField.Location,
        condition: !this.userLocationInRoleReqs,
      },
      {
        name: QualityGuidanceField.WorkingHours,
        condition: this.workingHoursOverlapTooLow,
      },
      {
        name: QualityGuidanceField.Projects,
        condition: !this.selectedProjectsHaveRequiredSkills,
      },
    ];

    return fields.filter((field) => !!field.condition);
  }

  @computed get solidApplicationGuidanceInvalidFields(): FieldCondition[] {
    const fields: FieldCondition[] = [
      {
        name: QualityGuidanceField.StandOut,
        condition: !this.pitch,
      },
      {
        name: QualityGuidanceField.Skills,
        condition:
          !this.requiredSkillsEvaluation?.every(
            (skill) => skill.inRequiredRating,
          ) || this.missingPreferredSkills.length > 0,
      },
      {
        name: QualityGuidanceField.CompanyQuestion,
        condition: this.missingRequiredCustomQuestions.length > 0,
      },
      {
        name: QualityGuidanceField.Availability,
        condition: this.availabilityNotMatchingRole,
      },
      {
        name: QualityGuidanceField.Projects,
        condition:
          !!this.profileStore?.localHiddenAndSelectedProjects &&
          this.profileStore.localHiddenAndSelectedProjects.selected.length < 3,
      },
    ];

    return fields.filter((field) => !!field.condition);
  }

  @computed get meetsBasicRequirementsInvalidFields(): FieldCondition[] {
    const fields: FieldCondition[] = [
      {
        name: QualityGuidanceField.Role,
        condition: this.isUserMissingRole,
      },
      {
        name: QualityGuidanceField.Rate,
        condition:
          (!this.hourlyRateRange?.min || !this.hourlyRateRange.max) &&
          (!this.currentRole?.collectMonthlyRate ||
            (this.currentRole.collectMonthlyRate && !this.monthlyRateRange)),
      },
      {
        name: QualityGuidanceField.Skills,
        condition: this.missingRequiredSkills.length > 0,
      },
      {
        name: QualityGuidanceField.Availability,
        condition: !this.availability.hoursPerWeek,
      },
      {
        name: QualityGuidanceField.WorkingHours,
        condition: !this.workingHours?.daily,
      },
      {
        name: QualityGuidanceField.Projects,
        condition: !!this.profileStore?.tooLittleProjects,
      },
    ];

    return fields.filter((field) => !!field.condition);
  }

  @computed get allInvalidRequirementsGuidanceFields(): string[] {
    const order = [
      QualityGuidanceField.Role,
      QualityGuidanceField.Skills,
      QualityGuidanceField.StandOut,
      QualityGuidanceField.CompanyQuestion,
      QualityGuidanceField.Rate,
      QualityGuidanceField.Location,
      QualityGuidanceField.Availability,
      QualityGuidanceField.WorkingHours,
      QualityGuidanceField.Projects,
    ];

    // Create a mapping from QualityGuidanceField to index
    const indexMap = invert(order);

    const allInvalidRequirementsGuidanceFields = uniq([
      ...this.meetsBasicRequirementsInvalidFields.map((field) => field.name),
      ...this.solidApplicationGuidanceInvalidFields.map((field) => field.name),
      ...this.aboveAverageGuidanceInvalidFields.map((field) => field.name),
      ...this.impressiveApplicationGuidanceInvalidFields.map(
        (field) => field.name,
      ),
    ]);

    return sortBy(
      allInvalidRequirementsGuidanceFields,
      (qualityGuidanceField) => indexMap[qualityGuidanceField] ?? order.length,
    );
  }

  @computed get allInvalidRequirementsGuidanceFieldsString(): string {
    return joinWithCommasAnd(this.allInvalidRequirementsGuidanceFields);
  }

  @computed get isLocationOnlyInvalidField(): boolean {
    return (
      this.allInvalidRequirementsGuidanceFields.length === 1 &&
      this.allInvalidRequirementsGuidanceFields[0] ===
        QualityGuidanceField.Location
    );
  }

  @computed get qualityGuidanceData(): Record<
    GuidanceStep,
    {
      title: string;
      description: string;
      progress: number;
    }
  > {
    const locationOnlyDescription = this.isLocationOnlyInvalidField
      ? 'Your chances of acceptance are lower since your location is outside of the company’s requirements.'
      : undefined;
    return {
      [GuidanceStep.NeedsImprovement]: {
        title: 'Your application needs some work.',
        description:
          locationOnlyDescription ||
          `Review the guidance in the following sections to improve your application: ${this.allInvalidRequirementsGuidanceFieldsString}.`,
        progress: 5,
      },
      [GuidanceStep.MeetsBasicRequirements]: {
        title:
          'Your application meets some requirements but should be refined.',
        description:
          locationOnlyDescription ||
          `Consider refining ${this.allInvalidRequirementsGuidanceFieldsString} for a more polished submission.`,
        progress: 25,
      },
      [GuidanceStep.Solid]: {
        title:
          'Your application is on the right track but has room for improvement.',
        description:
          locationOnlyDescription ||
          `Good job so far! Just a few tweaks in ${this.allInvalidRequirementsGuidanceFieldsString} might help.`,
        progress: 50,
      },
      [GuidanceStep.AboveAverage]: {
        title: 'Your application is thorough and meets requirements.',
        description:
          locationOnlyDescription ||
          `You're almost there! Consider refining your ${
            this.allInvalidRequirementsGuidanceFieldsString
          } section${
            this.allInvalidRequirementsGuidanceFields.length > 1 ? 's' : ''
          } to highlight your strengths.`,
        progress: 75,
      },
      [GuidanceStep.Impressive]: {
        title:
          'Your application is very impressive and there’s nothing significant left to refine.',
        description:
          locationOnlyDescription ||
          'You should feel confident submitting your application.',
        progress: 100,
      },
    };
  }

  @action saveApplicationData = (): void => {
    if (
      this.rid !== undefined &&
      this.authStore.withSaveApplicationProgress &&
      !this.readonly &&
      !this.aid &&
      typeof window === 'object'
    ) {
      const customQuestionsObj: SerializedCustomQuestions = {};

      this.answers.forEach((value, key) => {
        customQuestionsObj[key] = value;
      });

      try {
        const existingDataString = localStorage.getItem(
          `applicationData_${this.rid}_${this.authStore.uid}`,
        );

        let existingData: StoredApplicationData = {};

        if (existingDataString) {
          // Parse and validate the existing data using Zod
          existingData = storedApplicationDataSchema.parse(
            JSON.parse(existingDataString),
          );
        }

        const dataToStore: StoredApplicationData = {
          ...existingData,
          ...(this.pitch !== undefined && { pitch: this.pitch }),
          ...(this.answers.size > 0 && { customQuestions: customQuestionsObj }),
          ...(this.hourlyRateRange && {
            hourlyRateRange: this.hourlyRateRange,
          }),
          ...(this.monthlyRateRange && {
            monthlyRateRange: this.monthlyRateRange,
          }),
          ...(this.availability && { availability: this.availability }),
          ...(this.workingHours && { workingHours: this.workingHours }),
        };

        const validatedData = storedApplicationDataSchema.parse(dataToStore);

        localStorage.setItem(
          `applicationData_${this.rid}_${this.authStore.uid}`,
          JSON.stringify(validatedData),
        );
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.error(
            'Validation error while saving application data:',
            error.errors,
          );
        } else {
          console.error('Error while saving application data:', error);
        }
      }
    }
  };

  @action clearApplicationDataFromLocalStorage = (): void => {
    if (!this.rid) return;

    try {
      const key = `applicationData_${this.rid}_${this.authStore.uid}`;
      if (typeof window === 'object') {
        localStorage.removeItem(key);
      }
    } catch (error) {
      console.error(
        'Error while clearing application data from local storage:',
        error,
      );
      // Handle the error or log it for debugging purposes
    }
  };

  @action setMode = (mode: ApplicationViewMode): void => {
    this.mode = mode;
  };

  @action setTeamUpRequestResponses = (
    teamUpRequestResponses: ApplicationStoreData['teamUpRequestResponses'],
  ): void => {
    this.teamUpRequestResponses = teamUpRequestResponses;
  };

  @action setPitch = (pitch?: string): void => {
    this.pitch = pitch;
    this.saveApplicationData();
  };

  @action setAbout = (about?: string): void => {
    this.about = about;
  };

  @action setAnswer = (qid: string, text: string): void => {
    this.answers?.set(qid, text);
    this.saveApplicationData();
  };

  @action setCreatedWithMissingRole = (): void => {
    if (!this.currentRole) {
      this.createdWithMissingRole = true;
      return;
    }
    this.createdWithMissingRole =
      !!this.missionsStore?.currentMission?.getMissingRole(
        this.currentRole?.rid,
      );
  };

  @action setMinHourlyRate = (rate: number): void => {
    this.hourlyRateRange = {
      min: rate,
      max: this.hourlyRateRange?.max || 0,
    };
    this.saveApplicationData();
  };

  @action setMaxHourlyRate = (rate: number): void => {
    this.hourlyRateRange = {
      min: this.hourlyRateRange?.min || 0,
      max: rate,
    };
    this.saveApplicationData();
  };

  @action setMonthlyRateRange = (rate: ApplicationRateRange): void => {
    this.monthlyRateRange = rate;
  };

  @action removeMonthlyRateRange = (): void => {
    this.monthlyRateRange = undefined;
  };

  @action setAvailability = (availability: AvailabilitySummaryObject): void => {
    this.availability = {
      startDate: new Date(availability.availableFrom || '').toISOString(),
      hoursPerWeek: availability.weeklyHoursAvailable || 0,
      notes: availability.notes || '',
    };
    this.saveApplicationData();
  };

  @action setWorkingHours = (workingHours: WorkingHoursSchema): void => {
    this.workingHours = workingHours;
    this.saveApplicationData();
  };

  @action setHourlyRateRange = (
    hourlyRateRange: ApplicationRateRange,
  ): void => {
    this.hourlyRateRange = hourlyRateRange;
    this.saveApplicationData();
  };

  @action replaceHiddenProject = (oldId: string, eid: ExperienceId): void => {
    this.hiddenProjects.delete(oldId);
    this.hiddenProjects.set(eid, true);
  };

  @action setHiddenProject = (projectId: string, isHidden: boolean): void => {
    isHidden
      ? this.hiddenProjects?.set(projectId, isHidden)
      : this.hiddenProjects.delete(projectId);
  };

  @action setHiddenProjects = (projectsIds: string[]): void => {
    this.hiddenProjects = new Map(projectsIds.map((id) => [id, true]) || []);
  };

  @action setSubmitted = (submitted: boolean): void => {
    this.submitted = submitted;
  };

  @action setDetailsMenuOpen = (open: boolean): void => {
    this.detailsMenuOpen = open;
  };

  @action setApplicationStep = (step: ApplicationStep): void => {
    this.step = step;
  };

  @action setSuggestedTeammates = (
    teammates: TeammateSuggestionObject[],
  ): void => {
    this.suggestedTeammates = teammates;
  };

  @action setRequestedTeammates = (
    teammates: MinimalRecommendationData[],
  ): void => {
    this.requestedTeammates = teammates;
  };

  @action setTeammateSearchResults = (
    teammates: TeammateSuggestionObject[] | undefined,
  ): void => {
    this.teammateSearchResults = teammates;
  };

  @action onTeammateRequest = (uid: UserId, requested: boolean): void => {
    requested ? this.addRequest(uid) : this.removeRequest(uid);
  };

  @action addRequest = (uid: UserId): void => {
    const request: MinimalRecommendationData = {
      userId: uid,
    };
    this.setRequestedTeammates([...this.requestedTeammates, request]);
  };

  @action removeRequest = (uid: UserId): void => {
    this.setRequestedTeammates(
      this.requestedTeammates.filter((teammate) => teammate.userId !== uid),
    );
  };

  @action setShowSkipPostApplicationSuggestionsModal = (
    show: boolean,
  ): void => {
    this.showSkipPostApplicationSuggestionsModal = show;
  };

  @action setUpdateAvailabilityOnSubmit = (shouldUpdate?: boolean) => {
    this.updateAvailabilityOnSubmit = shouldUpdate;
  };

  @action setUpdateWorkingHoursOnSubmit = (shouldUpdate?: boolean) => {
    this.updateWorkingHoursOnSubmit = shouldUpdate;
  };

  @action setRequirementsModalOpen = (open: boolean) => {
    this.requirementsModalOpen = open;
  };

  @action setDidGeneratePitchSuggestion = (): void => {
    this.didGeneratePitchSuggestion = true;
  };

  @action setApplicationData = (data: ApplicationStoreData['data']): void => {
    this.data = data;
  };

  @action setApplicationStatus = (
    application: AdminMissionApplicationObject,
  ): void => {
    if ('internalStatus' in this.data) {
      this.data.reviewStatus = application.reviewStatus;
      this.data.internalStatus = application.internalStatus;
      this.data.status = application.status;
      this.data.exclusiveStatus = application.exclusiveStatus;
      this.data.accepted = application.accepted;
      this.data.proposedToClient = application.proposedToClient;
      this.data.lowCompetitiveness = application.lowCompetitiveness;
      this.data.rejectionReason = application.rejectionReason;
    }
  };
}
