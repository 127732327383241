import React from 'react';
import { profilePicturesByCategory } from '@a_team/models/dist/constants/Profile';
import AuthStore from '@src/stores/Auth';
import Profile from '@src/stores/Profile/Profile';
import { Link } from 'react-router-dom';

export interface ProfileStep {
  id: string;
  label: string | JSX.Element;
  completed: boolean;
  haveNotHadThreeJobs?: boolean;
  duration?: number;
}

const isDefaultAvatar = (url: string) => {
  return Object.values(profilePicturesByCategory).includes(url);
};

export const getProfileSteps = (
  profile: Profile,
  auth: AuthStore,
  hasCalendarSetup: boolean,
  hasProfilePictureIssues: boolean,
): ProfileStep[] => {
  const haveNotHadThreeJobs =
    auth.user?.profileCompleteness?.haveNotHadThreeJobs;

  const hasProfilePicture =
    !!profile?.data.profilePictureURL &&
    !isDefaultAvatar(profile?.data.profilePictureURL);
  const hasAboutMe =
    !!profile?.data?.aboutMe && profile?.data.aboutMe?.length > 249;
  const hasProjects =
    profile?.data?.projects?.filter(
      (project) => !project.isMissingMinimumRequirements,
    ).length >= 5;
  const hasJobs = !!(profile?.data?.jobs?.length > 2 || haveNotHadThreeJobs);
  const hasSkills = profile?.allSkills?.length > 4;
  const hasIndustries = profile?.industryExperiences?.length > 2;
  const hasResumeOrLinkedin =
    !!profile?.data.cvURL || !!profile?.data.linkedIn?.username;
  const hasConnections =
    !!(profile?.totalOverallConnections > 0) ||
    !!(profile?.totalPendingConnections > 0);

  const hasPreferredRates =
    !!profile?.minimumHourlyRate && !!profile.minimumMonthlyRate;

  const steps: ProfileStep[] = [
    {
      id: 'resumeLinkedin',
      label: 'Upload your resume or add your LinkedIn URL',
      completed: hasResumeOrLinkedin,
    },
    {
      id: 'profilePicture',
      label: 'Upload a profile picture',
      completed: hasProfilePicture,
    },
    {
      id: 'profilePictureIssues',
      label: 'Upload a profile picture that meets community guidelines',
      completed: !hasProfilePictureIssues,
    },
    {
      id: 'aboutMe',
      label: 'Write an “About me” section with at least 250 characters',
      completed: hasAboutMe,
    },
    {
      id: 'projects',
      label: 'Add at least 5 completed projects',
      completed: hasProjects,
    },
    {
      id: 'jobs',
      label: 'Add at least 3 jobs',
      completed: hasJobs,
      haveNotHadThreeJobs,
    },
    { id: 'skills', label: 'Add at least 5 skills', completed: hasSkills },
    {
      id: 'industries',
      label: 'Add at least 3 industries',
      completed: hasIndustries,
    },
    {
      id: 'preferredRates',
      label: 'Add your preferred minimum hourly and monthly rate',
      completed: hasPreferredRates,
    },
  ];

  steps.push({
    id: 'calendar',
    label: (
      <div>
        Connect your calendar{' '}
        <Link
          style={{
            color: '#6D00D7',
            textDecoration: 'underline',
          }}
          to="/settings?menu=interviews"
        >
          here
        </Link>{' '}
        to enable interviews
      </div>
    ),
    completed: hasCalendarSetup,
  });

  if (auth.fullAccess) {
    steps.push({
      id: 'connections',
      label:
        'Send a connection request to a builder you’d like to team up with',
      completed: hasConnections,
    });
  }

  return steps;
};

export const checkProfileCompletion = (profileSteps: ProfileStep[]) => {
  const totalCompleted = profileSteps.filter((step) => step.completed).length;
  const totalSteps = profileSteps.length;
  const allCompleted = totalCompleted === totalSteps;

  return { totalCompleted, allCompleted, totalSteps };
};
