import { ServiceAuth, ServiceEndpoint } from './utils';
import { ObjectId } from 'mongodb';
import { CompanyId } from '@a_team/models/dist/Company';

export const BasePath = '/companiesV2';

export interface RejectCompanyRequest {
  rejectedUntil?: Date;
  sendRejectionEmail?: boolean;
}

export interface ApproveCompanyRequest {
  sendWelcomeEmail?: boolean;
}

export interface PendingCompanyUser {
  _id: {
    userId: ObjectId;
  };
  companyId: ObjectId;
  companyName: string;
  companyUrl: string;
  companyEmployeeCount?: number;
  companyRevenue?: number;
  companyFunding?: number;
  companyBuildingDescription?: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  userLinkedIn?: string;
}

export default class CompaniesEndpointV2 extends ServiceEndpoint {
  public getPendingCompaniesUsers(
    auth: ServiceAuth,
  ): Promise<PendingCompanyUser[]> {
    return this.fetch(auth, BasePath + `/registration/pending`, null, 'get');
  }

  public getRejectedCompaniesUsers(
    auth: ServiceAuth,
  ): Promise<PendingCompanyUser[]> {
    return this.fetch(auth, BasePath + `/registration/rejected`, null, 'get');
  }

  public rejectCompany(
    auth: ServiceAuth,
    cid: CompanyId,
    request: RejectCompanyRequest,
  ): Promise<void> {
    return this.fetch(
      auth,
      `${BasePath}/registration/${cid}/pending`,
      null,
      'put',
      request,
    );
  }

  public approveCompany(
    auth: ServiceAuth,
    cid: CompanyId,
    request: ApproveCompanyRequest,
  ): Promise<void> {
    return this.fetch(
      auth,
      `${BasePath}/registration/${cid}/approve`,
      null,
      'put',
      request,
    );
  }

  public setCompanyPending(auth: ServiceAuth, cid: CompanyId): Promise<void> {
    return this.fetch(
      auth,
      `${BasePath}/registration/${cid}/pending`,
      null,
      'put',
    );
  }
}
