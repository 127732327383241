import React, { ReactElement } from 'react';
import {
  MissionPaymentCycleInvoiceObject,
  MissionRolePlatformFeeInvoiceObject,
} from '@a_team/models/dist/InvoiceObject';
import FooterTotal from '../FooterTotal';
import HeroSections, { HeroAddressSection, HeroSection } from '../HeroSections';
import PageLayout from '../PageLayout';
import { stringifyMinutes, stringifyDate } from '@src/helpers/time';
import Table from '../Table';
// import RoleAvatar from '../RoleAvatar';

interface Props {
  invoice: MissionPaymentCycleInvoiceObject;
  hero?: boolean;
  roles: MissionPaymentCycleInvoiceObject['rolesInvoices'];
  hasMore?: boolean;
}

export default function MissionMissionPaymentCycleInvoicePage(
  props: Props,
): ReactElement {
  const { invoice, hero, hasMore, roles } = props;
  const {
    period,
    to,
    from,
    billingDetails,
    subtotal,
    totalMinutes,
    clientInvoiceRoleDetails,
  } = invoice;

  let footer;
  if (!hasMore) {
    const subtotalStr = `$${subtotal.toLocaleString()}`;
    const platformMargin = `${invoice.platformMargin * 100}%`;
    const platformFee = `$${invoice.platformFee.toLocaleString()}`;
    const total = `$${invoice.totalAmount.toLocaleString()}`;

    footer = (
      <FooterTotal
        details={{
          ...(!invoice.platformFee && !invoice.discountAmount
            ? {
                'Total Hours': stringifyMinutes(totalMinutes),
              }
            : null),
          Subtotal: subtotalStr,
          ...(invoice.discountAmount
            ? {
                [invoice.discountReason
                  ? `Discount (${invoice.discountReason})`
                  : 'Discount']: `$${invoice.discountAmount.toLocaleString()}`,
              }
            : null),
          ...(invoice.platformFee
            ? {
                [`Platform Fee (${platformMargin})`]: platformFee,
              }
            : null),
        }}
        total={total}
      >
        <h4>Notes</h4>
        <p>
          For a detailed breakdown of each builder's work please log into
          platform.a.team. These can be found in the “Documents” section of your
          mission.
        </p>
        <p>ACH payments can take up to five business days.</p>
        <p>
          If you have any questions, contact us at:{' '}
          <a href="mailto:help@a.team">help@a.team</a>
        </p>
      </FooterTotal>
    );
  }

  return (
    <PageLayout invoice={invoice} period={period} footer={footer}>
      {!!hero && (
        <>
          <HeroSections>
            <HeroSection grow title="Summary for Period">
              {stringifyDate(period.startDate, true)}-
              {stringifyDate(period.endDate)}
            </HeroSection>
          </HeroSections>
          <HeroSections>
            {billingDetails ? (
              <HeroSection title="Remit to ATeams, Inc." grow>
                ACH/Domestic wires
                <br />
                Bank name: {billingDetails.bankName}
                <br />
                Routing number: {billingDetails.routingNumber}
                <br />
                Account number: {billingDetails.accountNumber}
                <br />
                SWIFT code: {billingDetails.swiftCode}
              </HeroSection>
            ) : (
              <HeroSection title="Remit to ATeams, Inc." grow children={null} />
            )}

            <HeroAddressSection title="From" address={from} />
            <HeroAddressSection title="To" address={to} />
          </HeroSections>
        </>
      )}
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Role</th>
            <th># of Hours</th>
            <th>$ per Hour</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((item) => {
            const rid = item?.rid;
            const clientInvoiceRoleDetailsItem =
              (clientInvoiceRoleDetails?.find((x) => x.rid === rid) ||
                {}) as Pick<
                MissionRolePlatformFeeInvoiceObject,
                'rid' | 'totalMinutes' | 'hourlyRate' | 'totalPayments'
              >;

            const { totalMinutes, hourlyRate, totalPayments } = {
              ...item,
              ...clientInvoiceRoleDetailsItem, // clientInvoiceRoleDetailsItem overrides if present
            };

            return (
              <tr key={item.iid}>
                <td>
                  {/*<RoleAvatar*/}
                  {/*  src={item.user.profilePictureURL}*/}
                  {/*  alt={item.user.fullName}*/}
                  {/*  size={18}*/}
                  {/*/>{' '}*/}
                  {item.user.fullName}
                </td>
                <td>{item.roleCategory.title}</td>
                <td>{stringifyMinutes(totalMinutes)}</td>
                <td>${hourlyRate.toLocaleString()}</td>
                <td>
                  <strong>${totalPayments.toLocaleString()}</strong>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </PageLayout>
  );
}
