import { Toggle } from '@a_team/ui-components';
import { Breakpoints } from '@ateams/components';
import React from 'react';
import { createUseStyles } from 'react-jss';

interface RecommendationProps {
  id: string;
  publicFeedback?: string;
  role: string;
  industry: string;
  period: string;
  companyLogo: string;
  companyName: string;
  visible?: boolean;
  isEditMode?: boolean;
  onVisibilityChange: (visible: boolean) => void;
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px',
    borderRadius: '8px',
  },
  companyLogoContainer: {
    display: 'flex',
    marginBottom: '16px',
    justifyContent: 'flex-start',
  },
  companyLogo: {
    width: '40px',
    height: '40px',
    borderRadius: '9px',
    objectFit: 'cover',
  },
  recommendationHeader: {
    fontWeight: '500',
    fontSize: '16px',
    marginBottom: '8px',
    textAlign: 'left',
  },
  industryPeriod: {
    color: '#6E7177',
  },
  recommendationDetails: {
    fontSize: '14px',
    color: '#35373A',
    marginBottom: '16px',
    flexWrap: 'wrap',
    display: 'flex',
    textAlign: 'left',
    gap: '8px',
  },
  recommendationText: {
    fontSize: '16px',
    textAlign: 'left',
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginTop: '16px',
  },
  checkedLabel: {
    color: '#35373A',
    fontSize: '14px',
  },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    container: {
      flexDirection: 'row',
    },
  },
});

const Recommendation = ({
  publicFeedback,
  role,
  industry,
  period,
  companyLogo,
  companyName,
  visible,
  isEditMode,
  onVisibilityChange,
}: RecommendationProps): JSX.Element => {
  const styles = useStyles();

  const handleToggle = (isChecked: boolean) => {
    onVisibilityChange(isChecked);
  };

  return (
    <div className={styles.container}>
      <div className={styles.companyLogoContainer}>
        <img
          src={companyLogo}
          alt={companyName}
          className={styles.companyLogo}
        />
      </div>
      <div>
        <div className={styles.recommendationHeader}>
          Recommendation from {companyName}
        </div>
        <div className={styles.recommendationDetails}>
          Worked as a {role}{' '}
          <span className={styles.industryPeriod}> · {industry}</span>
          <span className={styles.industryPeriod}> · {period}</span>
        </div>
        <div className={styles.recommendationText}>{publicFeedback}</div>
        {isEditMode && (
          <div className={styles.toggleContainer}>
            <Toggle checked={!!visible} onChange={handleToggle} />
            <span className={styles.checkedLabel}>
              {visible ? 'Visible on profile' : 'Hidden from profile'}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Recommendation;
