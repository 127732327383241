import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Modal, { ModalBasicProps } from '..';
import SectionHeading from '@src/components/SectionHeading';
import { ExistingJob, Expertise, NewJob } from '@src/stores/Profile/models';
import {
  Button as CallToActionButton,
  Checkbox,
  Colors,
  Icon,
  IconType,
} from '@ateams/components';
import { createUseStyles } from 'react-jss';
import { Breakpoints } from '@ateams/components';
import { CompanyPicker } from '@src/components/CompanyPicker';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';
import { Company } from '@a_team/models/dist/Company';
import { SelectUserSkillsSubset } from './SelectUserSkillsSubset';
import { SpecializationSelect } from '@src/components/SpecializationSelect';
import DatePickerSelect from '@src/components/DatePicker/DatePickerSelect';
import {
  useApplyJobSuggestion,
  useGetProfileSuggestions,
} from '@src/rq/profileSuggestions';
import { useStores } from '@src/stores';
import { CompanyV2Picker } from '@src/components/CompanyV2Picker';
import { observer } from 'mobx-react';

interface Props extends ModalBasicProps {
  title?: string;
  onSubmit: (
    company: Company,
    role: string,
    skills: string[],
    specialization?: string,
    startDate?: string,
    endDate?: string,
  ) => void;
  selectedItems?: Expertise[];
  placeholder?: string;
  buttonText?: string;
  displayType?: 'score' | 'tags';
  singleSelection?: boolean;
  required?: boolean;
  isOwner?: boolean;
  jobToUpdate?: NewJob | ExistingJob;
  mainAndAdditionalSkills: Expertise[];
  isSuggestedJob?: boolean;
}

const defaultProps = {
  displayType: 'score',
  singeSelection: false,
};

const useStyles = createUseStyles({
  modal: {
    display: 'flex',
    maxWidth: 745,
  },
  title: {
    fontSize: 17,
  },
  legend: {
    paddingTop: 10,
  },
  main: { width: '100%', minHeight: '22rem', overflow: 'auto' },
  [`@media (min-width: ${Breakpoints.sm}px)`]: {
    modal: {
      width: '505px !important',
      padding: '0px !important',
    },
    title: {
      fontSize: 20,
    },
    main: {
      minWidth: '60%',
      flex: 1,
      padding: '24px 24px 0px 24px',
      background: '#FFFFFF',
    },
    legend: {
      width: '40%',
      display: 'flex',
      padding: '10px 24px 10px 24px',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  },
  error: {
    color: Colors.danger,
    display: 'flex',
    justifyContent: 'end',
  },
  applicableSkills: {
    [`@media (max-height: 750px)`]: {
      display: 'none',
    },
  },
  datePicker: {
    margin: 0,
  },
  titleInput: {
    '& input': {
      padding: '0',
    },
    // select div child
    '& > div': {
      borderRadius: '4px',
      minHeight: '40px',
      padding: '5px 12px',
    },
  },
  specializationSelect: {
    margin: '12px 0',
    height: '40px',
  },
  companyPicker: {
    height: '40px',
  },
  suggestion: {
    display: 'flex',
    flexDirection: 'column',
    background: Colors.banner,
    padding: 12,
    borderRadius: 4,
    gap: 10,
    margin: '8px 4px 15px 0',
  },
  suggestionTitle: {
    color: Colors.secondaryDark,
    fontSize: 15,
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  suggestionDescription: {
    fontSize: 14,
    fontWeight: 500,
    color: Colors.dark,
  },
});

const JobExperienceModalWithoutObserver = (props: Props): ReactElement => {
  const { auth } = useStores();
  const styles = useStyles();
  const {
    onClose,
    onSubmit,
    title,
    selectedItems,
    placeholder,
    buttonText,
    displayType,
    singleSelection,
    required,
    open,
    jobToUpdate,
    isOwner = true,
    mainAndAdditionalSkills,
    isSuggestedJob,
    ...left
  } = props;

  const yearsBack = 50;
  const [titleOrRole, setTitleOrRole] = useState(jobToUpdate?.jobRole || '');
  const [company, setCompany] = useState<
    (Company & { url?: string; v2?: boolean }) | null
  >(null);
  const [companyError, setCompanyError] = useState('');
  const [nameError, setNameNameError] = useState('');
  const [applicableSkillIds, setApplicableSkillIds] = useState(
    jobToUpdate?.applicableTalentSkills?.map((s) => s.talentSkillId) ??
      ([] as string[]),
  );
  const [specializationId, setSpecializationId] = useState<string | undefined>(
    jobToUpdate?.applicableTalentSpecialization?.talentSpecializationId,
  );
  const dateTwoYearsAgo = useMemo(() => getDateTwoYearsAgo(), []);
  const [startDate, setStartDate] = useState(
    jobToUpdate?.startDate?.toString() || dateTwoYearsAgo.toString(),
  );
  const [endDate, setEndDate] = useState(
    jobToUpdate?.endDate?.toString() || new Date().toString(),
  );
  const [dateError, setDateError] = useState('');
  const [stillWorkHere, setStillWorkHere] = useState(!jobToUpdate?.endDate);
  const { mutateAsync: applyJobSuggestion } = useApplyJobSuggestion();
  const { data: profileSuggestions } = useGetProfileSuggestions();
  const handleSubmit = useCallback(async () => {
    setDateError('');

    if (!titleOrRole) {
      setNameNameError('Value is required');
    }

    if (!company) {
      setCompanyError('Value is required');
    }

    if (
      startDate &&
      endDate &&
      !stillWorkHere &&
      new Date(startDate).getTime() > new Date(endDate).getTime()
    ) {
      setDateError('Start date must come before the end date');
      return;
    }

    if (jobToUpdate?.isSuggested && profileSuggestions?.jobs?.sid) {
      await applyJobSuggestion({
        sid: profileSuggestions?.jobs?.sid,
        eid: (jobToUpdate as NewJob)._id,
        editedExperience: {
          ...jobToUpdate,
          name: company?.name || jobToUpdate.name,
          jobRole: titleOrRole,
        },
      });
    }

    if (company && titleOrRole) {
      onSubmit(
        company,
        titleOrRole,
        applicableSkillIds,
        specializationId,
        startDate,
        stillWorkHere ? undefined : endDate,
      );
    }
  }, [
    company,
    titleOrRole,
    applicableSkillIds,
    specializationId,
    startDate,
    endDate,
    stillWorkHere,
  ]);

  useEffect(() => {
    open && !jobToUpdate && setTitleOrRole('');
  }, [open]);

  useEffect(() => {
    setNameNameError('');
  }, [titleOrRole]);

  useEffect(() => {
    setCompanyError('');
  }, [company]);

  useEffect(() => {
    if (jobToUpdate) {
      jobToUpdate.startDate && setStartDate(jobToUpdate.startDate.toString());
      jobToUpdate.endDate &&
        setEndDate(jobToUpdate.endDate.toString() ?? new Date().toString());
      jobToUpdate.jobRole && setTitleOrRole(jobToUpdate.jobRole);
      jobToUpdate.applicableTalentSpecialization?.talentSpecializationId &&
        setSpecializationId(
          jobToUpdate.applicableTalentSpecialization.talentSpecializationId,
        );
      setStillWorkHere(!jobToUpdate.endDate);
    }
  }, [jobToUpdate]);

  useEffect(() => {
    setDateError('');

    if (startDate && !stillWorkHere && endDate) {
      if (new Date(startDate) > new Date(endDate)) {
        setDateError('Start date must come before the end date');
      }
    }
  }, [startDate, endDate, stillWorkHere]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      hideCloseButton={false}
      className={styles.modal}
      style={{
        flexDirection: 'column',
      }}
      {...left}
    >
      <div className={styles.main}>
        {isSuggestedJob && (
          <div className={styles.suggestion}>
            <div className={styles.suggestionTitle}>
              <Icon size="smaller" type={IconType.SuggestedTeams} />
              Adding suggested job
            </div>
            <div className={styles.suggestionDescription}>
              This job is suggested based on your LinkedIn, resume or A.Team
              activity.
            </div>
          </div>
        )}
        {title && (
          <SectionHeading className={styles.title} isFirst>
            {title}
          </SectionHeading>
        )}

        <div>
          <OutlinedInput
            data-testing-id={'profile-job-title-text-input'}
            className={styles.titleInput}
            placeholder={'Title...'}
            maxLength={100}
            style={{ width: '100%', margin: '12px 0' }}
            value={titleOrRole}
            disabled={!isOwner}
            required
            errorTooltip={nameError}
            error={nameError !== ''}
            onChange={(e): void => setTitleOrRole(e.target.value)}
          />
        </div>
        <div>
          <SpecializationSelect
            className={styles.specializationSelect}
            onChange={(specialization) =>
              setSpecializationId(specialization?.id)
            }
            defaultValue={
              jobToUpdate?.applicableTalentSpecialization
                ?.talentSpecializationId ?? undefined
            }
          />
        </div>
        <div>
          {/* rm observer wrapper from component export */}
          {auth.withEnrichedCompaniesLinkedToExperiences ? (
            <CompanyV2Picker
              className={styles.companyPicker}
              onSelectCompanies={(companies) =>
                setCompany(companies[0] ? { ...companies[0], v2: true } : null)
              }
              error={companyError}
              disabled={!isOwner}
              initiallySelectedCompanyIds={
                jobToUpdate?.companyV2Id ? [jobToUpdate.companyV2Id] : []
              }
              displayName={jobToUpdate?.companyName ?? undefined}
              onlySearchVerified
              canAddCompany
            />
          ) : (
            <CompanyPicker
              inputTestingId={'profile-job-company-select'}
              className={styles.companyPicker}
              onSelectCompanies={(companies) =>
                setCompany(companies[0] || null)
              }
              canCreateNewCompanies={true}
              error={companyError}
              autoFocus
              disabled={!isOwner}
              initiallySelectedCompanyIds={
                jobToUpdate?.companyId ? [jobToUpdate.companyId] : []
              }
            />
          )}
        </div>
        <div style={{ margin: '20px 0' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p>Starting From</p>

            <DatePickerSelect
              className={styles.datePicker}
              onChange={({ date }) => setStartDate(date.toString())}
              date={startDate}
              yearsBack={yearsBack}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              opacity: stillWorkHere ? 0.4 : 1,
            }}
          >
            <div>
              <p>Ending In</p>
            </div>
            <div>
              <DatePickerSelect
                className={styles.datePicker}
                onChange={({ date }) => setEndDate(date.toString())}
                date={endDate}
                disabled={stillWorkHere}
                yearsBack={yearsBack}
              />
            </div>
          </div>
          <Checkbox
            label={'I still work here'}
            margin={'none'}
            checked={stillWorkHere}
            onChange={(e) => setStillWorkHere(e.currentTarget.checked)}
          />

          {dateError && (
            <p className={styles.error}>
              <span>{dateError}</span>
            </p>
          )}
        </div>
        <div className={styles.applicableSkills}>
          <p>Select Any of Your Skills That Are Applicable to This Position:</p>
          <SelectUserSkillsSubset
            mainAndAdditionalSkills={mainAndAdditionalSkills}
            onChangeSelected={setApplicableSkillIds}
            defaultSkillIds={jobToUpdate?.applicableTalentSkills?.map(
              (s) => s.talentSkillId,
            )}
          />
        </div>
      </div>
      <div className={styles.legend} style={{ width: '100%' }}>
        <CallToActionButton
          style={{
            alignSelf: 'flex-end',
            padding: '10px 20px',
            height: '40px',
          }}
          width={'auto'}
          onClick={handleSubmit}
          data-testing-id={'profile-job-submit-button'}
        >
          {jobToUpdate && !jobToUpdate.isSuggested
            ? 'Update'
            : 'Add Experience'}
        </CallToActionButton>
      </div>
    </Modal>
  );
};

JobExperienceModalWithoutObserver.defaultProps = defaultProps;

export const JobExperienceModal = observer(JobExperienceModalWithoutObserver);

const getDateTwoYearsAgo = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 2);
  return date;
};
