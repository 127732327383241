import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import Modal, { ModalBasicProps } from '@src/components/Modal';
import SectionHeading from '@src/components/SectionHeading';
import { createUseStyles } from 'react-jss';
import {
  BorderColors,
  BorderRadius,
  Button,
  Colors,
  Icon,
  IconType,
  Paddings,
  Spacing,
  Spinner,
} from '@ateams/components';
import { useGetReachoutAutomationPreviewForMission } from '@src/rq/emailFromTargeter';
import { useStores } from '@src/stores';
import cx from 'classnames';
import OutlinedInput from '@src/components/Inputs/OutlinedInput';

const useStyles = createUseStyles({
  container: {
    overflow: 'auto',
    padding: Paddings.xLarge,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  header: {
    marginBottom: Spacing.xLarge,
  },
  actionsBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '12px',
    paddingRight: '12px',
    gap: Spacing.medium,
  },
  roleCard: {
    padding: Paddings.large,
    textAlign: 'left',
    border: `1px solid ${BorderColors.light}`,
    borderRadius: BorderRadius.medium,
    marginBottom: Spacing.xLarge,
    '& strong': {
      marginBottom: Spacing.small,
      display: 'block',
    },
  },
  openInTargeterButton: {
    color: Colors.secondary,
  },
  openInTargeterButtonDisabled: {
    color: Colors.regular,
  },
  centerBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 100,
    marginBottom: Spacing.xLarge,
  },
  totalAudienceCount: {
    marginLeft: Spacing.small,
    color: Colors.regular,
    textDecoration: 'line-through',
  },
  totalAudience: {
    marginTop: Spacing.small,
    marginBottom: 0,
  },
  limitInput: {
    marginBottom: Spacing.small,
    textAlign: 'left',
  },
  recalculateButton: {
    marginLeft: Spacing.small,
  },
  recalculateButtonIcon: {
    marginRight: Spacing.small,
  },
});

const AUDIENCE_DEFAULT_LIMIT_AUTOMATED_REACHOUT = 50;

export interface AutomatedReachoutModalProps extends ModalBasicProps {
  missionId?: string;
  onSaveWithoutReachout?(): void;
  onSaveWithReachoutRedirect?(): void;
  onReachoutRedirect?(): void;
  saveAndOpenInTargeterLabel?: string;
  saveWithoutReachoutLabel?: string;
  saveWithReachoutLabel?: string;
}

const ROLE_HEADLINE_LIMIT = 150;

const AutomatedReachoutModal: React.FC<AutomatedReachoutModalProps> = ({
  open,
  onClose,
  onSaveWithoutReachout,
  onSaveWithReachoutRedirect,
  missionId,
  saveAndOpenInTargeterLabel,
  saveWithoutReachoutLabel,
  saveWithReachoutLabel,
  onReachoutRedirect,
}: AutomatedReachoutModalProps) => {
  const styles = useStyles();
  const { targeterTabManager } = useStores();
  const [maxNumberOfBuilders, setMaxNumberOfBuilders] = React.useState<number>(
    AUDIENCE_DEFAULT_LIMIT_AUTOMATED_REACHOUT,
  );
  const [maxNumberOfBuildersCache, setMaxNumberOfBuildersCache] =
    React.useState<number>(AUDIENCE_DEFAULT_LIMIT_AUTOMATED_REACHOUT);
  const { data, isLoading, refetch, isRefetching } =
    useGetReachoutAutomationPreviewForMission({
      missionId: missionId || '',
      maxNumberOfBuilders,
      enabled: false,
    });
  const initializeTabCache = async (cb?: () => void) => {
    if (!data?.roles || !missionId) return;
    targeterTabManager.initializeTabCacheFromAutomatedReachoutMissionPreview(
      missionId,
      data?.roles,
    );
    cb && cb();
  };
  useEffect(() => {
    if (data?.roles?.length) {
      setMaxNumberOfBuildersCache(maxNumberOfBuilders);
    }
  }, [data]);
  useEffect(() => {
    if (missionId) {
      refetch();
    }
  }, [missionId]);
  return (
    <Modal open={open} onClose={onClose} style={{ padding: 0, minWidth: 750 }}>
      <div className={styles.container}>
        <SectionHeading isFirst className={styles.header}>
          Confirm automated reachouts
        </SectionHeading>
        {isLoading || isRefetching ? (
          <div className={styles.centerBox}>
            <Spinner color="#333" />
          </div>
        ) : null}
        {!data && !isLoading && !isRefetching && (
          <div className={styles.centerBox}>
            <p>There are no eligible roles for reachout</p>
          </div>
        )}
        {data && data.roles?.length && !isLoading && !isRefetching ? (
          <div className={styles.limitInput}>
            <>
              <OutlinedInput
                label="Max number of builders"
                disabled={isLoading || isRefetching}
                value={maxNumberOfBuilders}
                type="number"
                onChange={(e) =>
                  !isLoading &&
                  !isRefetching &&
                  setMaxNumberOfBuilders(e.target.valueAsNumber)
                }
                endAdornment={
                  <Button
                    className={styles.recalculateButton}
                    color="regularLight"
                    squared
                    disabled={isLoading || isRefetching}
                    size="small"
                    width="auto"
                    onClick={() => refetch()}
                  >
                    <Icon
                      className={styles.recalculateButtonIcon}
                      type={IconType.Refresh}
                    />
                    {'Recalculate'}
                  </Button>
                }
              />
            </>
          </div>
        ) : null}
        {data?.roles.map((rolePreview) => (
          <div key={rolePreview.roleId} className={styles.roleCard}>
            <strong>{rolePreview.roleCategory}</strong>
            <p>
              {rolePreview.roleHeadline.slice(0, ROLE_HEADLINE_LIMIT)}
              {rolePreview.roleHeadline.length > ROLE_HEADLINE_LIMIT
                ? '...'
                : ''}
            </p>
            <p className={styles.totalAudience}>
              Total audience targeted:{' '}
              {Math.min(rolePreview.userCount, maxNumberOfBuildersCache)}
              {rolePreview.userCount > maxNumberOfBuildersCache ? (
                <span className={styles.totalAudienceCount}>
                  {rolePreview.userCount}
                </span>
              ) : null}
            </p>
          </div>
        ))}
        <div className={styles.actionsBar}>
          {!isLoading && !isRefetching && !data ? (
            <></>
          ) : (
            <>
              <Button
                squared
                disabled={isLoading || isRefetching}
                size="small"
                width="auto"
                color="transparent"
                className={cx(
                  styles.openInTargeterButton,
                  (isLoading || isRefetching) &&
                    styles.openInTargeterButtonDisabled,
                )}
                onClick={() => initializeTabCache(onReachoutRedirect)}
              >
                {'Open in targeter'}
              </Button>
              <Button
                squared
                disabled={isLoading || isRefetching}
                size="small"
                width="auto"
                color="transparent"
                className={cx(
                  styles.openInTargeterButton,
                  (isLoading || isRefetching) &&
                    styles.openInTargeterButtonDisabled,
                )}
                onClick={() => initializeTabCache(onSaveWithReachoutRedirect)}
              >
                {saveAndOpenInTargeterLabel || 'Save and open in targeter'}
              </Button>
            </>
          )}
          <Button
            squared
            size="small"
            width="auto"
            color="regularLight"
            onClick={onSaveWithoutReachout}
          >
            {saveWithoutReachoutLabel || 'Save without reachout'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default observer(AutomatedReachoutModal);
